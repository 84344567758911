export const GET_EMAIL_TEMPLATES = "GET_EMAIL_TEMPLATES";
export const GET_EMAIL_TEMPLATES_SUCCESS = "GET_EMAIL_TEMPLATE_SUCCESS";
export const GET_EMAIL_TEMPLATES_FAIL = "GET_EMAIL_TEMPLATES_FAIL";

export const GET_PROMOTER_EMAIL_TEMPLATES = "GET_PROMOTER_EMAIL_TEMPLATES";
export const GET_PROMOTER_EMAIL_TEMPLATES_SUCCESS = "GET_PROMOTER_EMAIL_TEMPLATE_SUCCESS";
export const GET_PROMOTER_EMAIL_TEMPLATES_FAIL = "GET_PROMOTER_EMAIL_TEMPLATES_FAIL";

export const EDIT_EMAIL_TEMPLATE = "EDIT_EMAIL_TEMPLATE";
export const EDIT_EMAIL_TEMPLATE_SUCCESS = "EDIT_EMAIL_TEMPLATE_SUCCESS";
export const EDIT_EMAIL_TEMPLATE_FAIL = "EDIT_EMAIL_TEMPLATE_FAIL";

export const RESET_STATE = "RESET_STATE";
