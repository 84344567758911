export const getRegion = () => {
  let region = localStorage.getItem("region"); // dev method

  if (!region) {
    region = getDefinedRegion();
    localStorage.setItem("region", region);
  }

  const url = window.location.origin;
  const defaultData = {
    url,
    theme: "sixtix",
    title: "SixTix",
    defaultTimeZone: "Europe/Belgrade",
    currency: "RSD",
    terms: `
      <p class="terms-title">TERMS AND CONDITIONS</p>
      <p class="sub-title"><strong>SELLER</strong></p>
      <ul class="terms-list">
      <li>SIXTIX DOO</li>
      <li>Humska 1</li>
      <li>7990 Other reservation services and related activities</li>
      <li>11000 Belgrade</li>
      <li>Company Registration Number: 21940291</li>
      <li>Tax Identification Number: 113886255</li>
      <li><a href="https://www.sixtix.com/ ">https://www.sixtix.com/</a></li>
      <li>Phone: +381 63 284 325</li>
      <li><a href="mailto:support@sixtix.com">support@sixtix.com</a></li>
      </ul>
      <h2>TERMS OF USE OF THE SIXTIX ONLINE SERVICE</h2>
      <p>Your use of the http://sixtix.com  website, as well as the service of purchasing/selling tickets online atsixtix.com (hereinafter referred to as the "Service"), is subject to these Terms of Use. These Terms of Use constitute an agreement between SIXTIX DOO (hereinafter "WAF," "Our") and visitors of the http://sixtix.com  website and/or ticket buyers (hereinafter "You," "Your"). If you are an organization selling tickets or managing ticket offices using the SIXTIX ticket office management system, your use and operation of that system are governed by the SIXTIX Seller Agreement and Terms of Service.</p>
      <h2>SERVICE</h2>
      <p>Availability and ticket prices. The organization solely determines the availability of the number of tickets for events or performances. The ticket prices are determined by the organization selling the tickets.</p>
      <h2>TICKETS</h2>
      <p>Availability and ticket prices. The organization solely determines the availability of the number of tickets for events or performances. The ticket prices are determined by the organization selling the tickets.<br />VAT Statement<br />VAT included in the price and no hidden costs</p>
      <h2>TICKET PURCHASE</h2>
      <p>If you purchase tickets from the seller through the Service, the ticket will be sent to you via email after the ticket purchase process is completed. You will not receive a paper copy of the ticket from WAF, only an electronic copy of the purchased ticket.<br />Financial Transaction Data Protection<br />When entering credit card data, confidential information is transmitted over a public network in a protected (encrypted) form using SSL protocol and PKI system, as the most current cryptographic technology. The security of data during purchase is guaranteed by the card processor, the Acquirer Bank, so the entire payment process is carried out on a secure page, whose processor is the Bank. At no time are credit card data available to our system.<br />Conversion Statement<br />All payments will be effected in Serbian currency – dinar (RSD). The amount your credit card account will be charged for is obtained through the conversion of the price in Euro into Serbian dinar according to the current exchange rate of the Serbian National Bank. When charging your credit card, the same amount is converted into your local currency according to the exchange rate of credit card associations. As a result of this conversion there is a possibility of a slight difference from the original price stated in our web site’.</p>
      <h2>REFUND OF FUNDS</h2>
      <p>The refund of previously purchased tickets is at the discretion of the selling organization (we do not have the discretionary right to refund money) and can only be done through that organization. In case of overpayment or other errors caused by the WAF service itself and when these errors are confirmed, a refund may be made at WAFs discretion. In that case, contact SIXTIX support via <a href="mailto:support@sixtix.com">support@sixtix.com</a>.<br />Refund of Charged Funds<br />In the event of returning goods and refunding funds to a customer who previously paid with any of the payment cards, either partially or in full, regardless of the reason for the return, WAF is obliged to make the refund exclusively through American Express (AmEx), DinaCard, Maestro/MasterCard, and VISA payment methods, meaning that the Electronic Money Institution, upon the merchants request, will refund the funds to the cardholders account.<br />Complaints are not common and may be initiated in case of card misuse or any other force majeure reasons. Please contact us by email, and we will resolve your complaint through direct communication as soon as possible.</p>
      <h2>CONTACT THE SELLING ORGANIZATION</h2>
      <p>If you have any questions for the ticket seller (event organizer), you can contact them using the contact information provided in the confirmation of receipt of your ticket, which WAF sends to you electronically.</p>
      <h2>ORDER CANCELLATION</h2>
      <p>We reserve the right to cancel any ticket purchase for any reason, including but not limited to incomplete or inaccurate payment information, purchasing irregular cards, or any other violation of the WAF Acceptable Use Policy, provisions, and terms USAGE POLICY<br />Your use of the service is subject to the "acceptable use policy" established by WAF. The main focus of this policy is not to use our service to commit acts that may be harmful to WAF or undermine WAFs ethical standards, values, and reputation. Malicious actions include intentional misuse of access, granted privileges or licenses, use of copyrighted material, or any infringement of intellectual property, hacking, involvement of third parties, DoS attacks, intentional misuse or misrepresentation of ticket purchases, altering routes, conducting robot-controlled purchases, or impersonating the WAF website. From time to time, our understanding of what is harmful may change as a result of new (technological) developments. Standards and values in using our service reflect social norms and values. Posting offensive images, texts, statements, or defamatory statements do not represent acceptable social norms and moral values, nor do statements lacking artistic, literary, or scientific value. We may, at our discretion, temporarily block your account or completely block access if you violate this WAF "acceptable use" policy.</p>
      <h2>PRIVACY POLICY</h2>
      <p>Your use of the service is also subject to WAFs privacy policy, which is updated from time to time to comply with current regulations. The Privacy Policy page can be accessed via a link on the WAF websites homepage. Please note that the Privacy Policy applies only to the Service and does not cover third-party websites or services linked through the Service. Additionally, all information you share with the ticket seller through WAF or outside of WAF, such as your name and email address, is subject to that sellers privacy policy<br />On behalf of WAF, we commit to maintaining the privacy of all our customers. We only collect necessary, basic customer/user data and data necessary for business and informing users in accordance with good business practices and in order to provide quality service. We give customers the option to choose, including the option to decide whether or not they want to be removed from mailing lists used for marketing campaigns. All customer/user data is strictly kept and available only to employees who need this data to perform their job. All WAF employees and business partners are responsible for adhering to the principles of privacy protection.</p>
      <h2>STATEMENTS, DISCLAIMER OF LIABILITY, AND LIMITATION OF LIABILITY</h2>
      <h2>STATEMENT</h2>
      <p>The service and website http://www.sixtix.com  are not intended for children under the age of 13. By using the Service, you promise that you are over 13 years old and have the authority or appropriate permission to purchase tickets purchased through <a href="http://www.sixtix.com ">http://www.sixtix.com </a>. </p>
      <h2>DISCLAIMER OF LIABILITY</h2>
      <p>The WAF service is offered "as is" and we make no warranties, express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement of intellectual property rights, or implied warranties arising from applicable law. We are not obligated to indemnify or defend you against lawsuits related to intellectual property infringement; and we cannot guarantee that the Service will operate without error or interruption.</p>
      <h2>LIMITATION OF LIABILITY</h2>
      <p>Under no circumstances will we be liable for any damages, including consequential, indirect, special, incidental, or punitive damages arising out of or in connection with your use of the Service. The limitations of liability in this section do NOT APPLY to liability for negligence, regardless of the form of action, and apply even if we are aware in advance of the possibility of damages and even if such damages were foreseeable. Limitations of liability also apply even if your available legal remedies are inadequate. If applicable law limits the application of the provisions of this section, our liability will still be limited.</p>
      <h2>OTHER TERMS</h2>
      <h2>AMENDMENTS</h2>
      <p>We may change these Terms and Conditions from time to time by posting an amended version on our website. At any time, we may change the WAF "acceptable use" policy or our privacy policy. We recommend that you periodically review these terms.</p>
      <h2>TRANSFER AND DURATION</h2>
      <p>Neither party may transfer this Agreement or any of its rights or obligations hereunder without the express written consent of the other, except for the transfer of the Agreement to a legal entity that, as a result of any merger of our company with another, would represent a newly formed legal entity. Except to the extent that transfer is not permitted in the preceding sentence, this Agreement shall be binding upon (and shall inure to the benefit of) the parties respective successors.</p>
      <h2>APPLICABLE LAW - SERBIA</h2>
      <p>This Agreement is made in the Serbia and shall be governed by Serbian law. The Customer agrees to the exclusive jurisdiction of the courts in Serbia.</p>
      <h2>COPYRIGHT</h2>
      <p>If you believe that your work is posted on the WAF website in violation of copyright without permission, and exceptions for fair use are not applicable or have been exceeded, you can send an email describing the misuse to <a href="mailto:support@sixtix.com">support@sixtix.com</a>.</p>
      <h2>SEVERABILITY</h2>
      <p>To the extent permitted by applicable law, the parties waive all legal provisions that would cause a clause of this agreement to be invalid or otherwise unenforceable in any respect. In the event that any provision of this agreement is nevertheless invalid or otherwise unenforceable, such provision shall be interpreted in a manner that comes as close as possible to the purpose intended by that provision (to the greatest extent permitted by applicable law), while the remaining provisions of this Agreement shall remain in force.</p>
      <h2>OTHER LEGAL DOCUMENTS</h2>
      <p>Privacy Policy (Visitor/Cardholder) WAF Seller Agreement and Terms of Service (Event Organizers).</p>
      <h2>MARKETING AND CONSENT</h2>
      <p>By providing your email address and phone number, you consent to their use for marketing purposes by SIXTIX DOO in accordance with the GDPR. This includes receiving promotional offers, event updates, and other relevant information. You have the right to withdraw your consent at any time by contacting us at support@sixtix.com. Your data will not be shared with third parties without your explicit consent and will be processed in line with our Privacy Policy.</p>
    `,
  }

  switch (region) { // host for prod
    case "fanceetickets":
      return {
        ...defaultData,
        theme: "fanceetickets",
        title: "FanceeTickets",
        terms: `
          <p className="terms-title">TERMS AND CONDITIONS</p>
          <p className="sub-title">
            <strong>SELLER</strong>
          </p>
          <ul className="terms-list">
            <li>WE ARE FANCEE DOO</li>
            <li>Humska 1</li>
            <li>7990 Other reservation services and related activities</li>
            <li>11000 Belgrade</li>
            <li>Company Registration Number: 21940291</li>
            <li>Tax Identification Number: 113886255</li>
            <li>
              <a
                href="https://fanceetickets.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.fanceetickets.com
              </a>
            </li>
            <li>Phone: +381 63 284 325</li>
            <li>
              <a
                href="mailto:info@fanceetickets.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                info@fanceetickets.com
              </a>
            </li>
          </ul>
          <h2>TERMS OF USE OF THE WE ARE FANCEE ONLINE SERVICE</h2>
          <p>
            Your use of the fanceetickets.com website, as well as the service of purchasing/selling 
            tickets online at fanceetickets.com (hereinafter referred to as the "Service"), is 
            subject to these Terms of Use. These Terms of Use constitute an agreement between 
            We Are Fancee Ltd. Belgrade (hereinafter "WAF," "Our") and visitors of the 
            fanceetickets.com website and/or ticket buyers (hereinafter "You," "Your"). If you 
            are an organization selling tickets or managing ticket offices using the WE ARE FANCEE 
            ticket office management system, your use and operation of that system are governed 
            by the WE ARE FANCEE Seller Agreement and Terms of Service.
          </p>

          <h2>SERVICE</h2>
          <p>
            Availability and ticket prices. The organization solely determines the availability of the 
            number of tickets for events or performances. The ticket prices are determined by the 
            organization selling the tickets.
          </p>

          <h2>TICKETS</h2>
          <p>
            Availability and ticket prices. The organization solely determines the availability of the 
            number of tickets for events or performances. The ticket prices are determined by the 
            organization selling the tickets.
            <br/>
            VAT Statement
            <br/>
            VAT included in the price and no hidden costs
          </p>

          <h2>TICKET PURCHASE</h2>
          <p>
            If you purchase tickets from the seller through the Service, the ticket will be sent to you via 
            email after the ticket purchase process is completed. You will not receive a paper copy of the ticket 
            from WAF, only an electronic copy of the purchased ticket.
            <br/>
            Financial Transaction Data Protection
            <br/>
            When entering credit card data, confidential information is transmitted over a public network in a 
            protected (encrypted) form using SSL protocol and PKI system, as the most current cryptographic 
            technology. The security of data during purchase is guaranteed by the card processor, the Acquirer 
            Bank, so the entire payment process is carried out on a secure page, whose processor is the Bank. 
            At no time are credit card data available to our system.
            <br/>
            Conversion Statement
            <br/>
            All payments will be effected in Serbian currency – dinar (RSD). The amount your credit
            card account will be charged for is obtained through the conversion of the price in Euro
            into Serbian dinar according to the current exchange rate of the Serbian National Bank.
            When charging your credit card, the same amount is converted into your local currency
            according to the exchange rate of credit card associations. As a result of this conversion
            there is a possibility of a slight difference from the original price stated in our web site’.
          </p>

          <h2>REFUND OF FUNDS</h2>
          <p>
            The refund of previously purchased tickets is at the discretion of the selling organization 
            (we do not have the discretionary right to refund money) and can only be done through that 
            organization. In case of overpayment or other errors caused by the WAF service itself and when 
            these errors are confirmed, a refund may be made at WAFs discretion. In that case, contact We 
            Are Fancee support via
            {" "}
            <a href="mailto:info@fanceetickets.com">info@fanceetickets.com</a>.
            <br/>
            Refund of Charged Funds
            <br/>
            In the event of returning goods and refunding funds to a customer who previously paid with any of 
            the payment cards, either partially or in full, regardless of the reason for the return, WAF 
            is obliged to make the refund exclusively through American Express (AmEx), DinaCard, Maestro/MasterCard, 
            and VISA payment methods, meaning that the Electronic Money Institution, upon the merchants request, 
            will refund the funds to the cardholders account.
            <br/>
            Complaints are not common and may be initiated in case of card misuse or any other force majeure reasons. 
            Please contact us by email, and we will resolve your complaint through direct communication as soon as possible.
          </p>

          <h2>CONTACT THE SELLING ORGANIZATION</h2>
          <p>
            If you have any questions for the ticket seller (event organizer), you can contact them using the contact 
            information provided in the confirmation of receipt of your ticket, which WAF sends to you electronically.
          </p>

          <h2>ORDER CANCELLATION</h2>
          <p>
            We reserve the right to cancel any ticket purchase for any reason, including but not limited to incomplete 
            or inaccurate payment information, purchasing irregular cards, or any other violation of the WAF Acceptable 
            Use Policy, provisions, and terms USAGE POLICY
            <br/>
            Your use of the service is subject to the "acceptable use policy" established by WAF. The main focus of this 
            policy is not to use our service to commit acts that may be harmful to WAF or undermine WAFs ethical standards, 
            values, and reputation. Malicious actions include intentional misuse of access, granted privileges or licenses, 
            use of copyrighted material, or any infringement of intellectual property, hacking, involvement of third parties, 
            DoS attacks, intentional misuse or misrepresentation of ticket purchases, altering routes, conducting 
            robot-controlled purchases, or impersonating the WAF website. From time to time, our understanding of what is 
            harmful may change as a result of new (technological) developments. Standards and values in using our service reflect 
            social norms and values. Posting offensive images, texts, statements, or defamatory statements do not represent 
            acceptable social norms and moral values, nor do statements lacking artistic, literary, or scientific value. We may, 
            at our discretion, temporarily block your account or completely block access if you violate this WAF "acceptable use" policy.
          </p>
          <h2>PRIVACY POLICY</h2>
          <p>
            Your use of the service is also subject to WAFs privacy policy, which is updated from time to time to 
            comply with current regulations. The Privacy Policy page can be accessed via a link on the WAF websites 
            homepage. Please note that the Privacy Policy applies only to the Service and does not cover third-party 
            websites or services linked through the Service. Additionally, all information you share with the ticket 
            seller through WAF or outside of WAF, such as your name and email address, is subject to that sellers 
            privacy policy
            <br/>
            On behalf of WAF, we commit to maintaining the privacy of all our customers. We only collect necessary, 
            basic customer/user data and data necessary for business and informing users in accordance with good business 
            practices and in order to provide quality service. We give customers the option to choose, including the option 
            to decide whether or not they want to be removed from mailing lists used for marketing campaigns. All customer/user 
            data is strictly kept and available only to employees who need this data to perform their job. All WAF employees 
            and business partners are responsible for adhering to the principles of privacy protection.
          </p>

          <h2>STATEMENTS, DISCLAIMER OF LIABILITY, AND LIMITATION OF LIABILITY</h2>
          <h2>STATEMENT</h2>
          <p>
            The service and website www.fanceetickets.com are not intended for children under the age of 13. 
            By using the Service, you promise that you are over 13 years old and have the authority or appropriate permission 
            to purchase tickets purchased through www.fanceetickets.com
          </p>

          <h2>DISCLAIMER OF LIABILITY</h2>
          <p>
            The WAF service is offered "as is" and we make no warranties, express or implied, including, but not limited to, 
            implied warranties of merchantability, fitness for a particular purpose, non-infringement of intellectual property 
            rights, or implied warranties arising from applicable law. We are not obligated to indemnify or defend you against 
            lawsuits related to intellectual property infringement; and we cannot guarantee that the Service will operate without 
            error or interruption.
          </p>

          <h2>LIMITATION OF LIABILITY</h2>
          <p>
            Under no circumstances will we be liable for any damages, including consequential, indirect, special, incidental, 
            or punitive damages arising out of or in connection with your use of the Service. The limitations of liability in 
            this section do NOT APPLY to liability for negligence, regardless of the form of action, and apply even if we are 
            aware in advance of the possibility of damages and even if such damages were foreseeable. Limitations of liability 
            also apply even if your available legal remedies are inadequate. If applicable law limits the application of the 
            provisions of this section, our liability will still be limited.
          </p>

          <h2>OTHER TERMS</h2>

          <h2>AMENDMENTS</h2>
          <p>
            We may change these Terms and Conditions from time to time by posting an amended version on our website. At any time, 
            we may change the WAF "acceptable use" policy or our privacy policy. We recommend that you periodically review these terms.
          </p>

          <h2>TRANSFER AND DURATION</h2>
          <p>
            Neither party may transfer this Agreement or any of its rights or obligations hereunder without the express written consent 
            of the other, except for the transfer of the Agreement to a legal entity that, as a result of any merger of our company 
            with another, would represent a newly formed legal entity. Except to the extent that transfer is not permitted in the 
            preceding sentence, this Agreement shall be binding upon (and shall inure to the benefit of) the parties respective successors.
          </p>

          <h2>APPLICABLE LAW - SERBIA</h2>
          <p>
            This Agreement is made in the Serbia and shall be governed by Serbian law. The Customer agrees to the exclusive jurisdiction of the courts in Serbia.
          </p>

          <h2>COPYRIGHT</h2>
          <p>
            If you believe that your work is posted on the WAF website in violation of copyright without permission, and exceptions for 
            fair use are not applicable or have been exceeded, you can send an email describing the misuse to {" "}
            <a href="mailto:info@fanceetickets.com">info@fanceetickets.com</a>.
          </p>

          <h2>SEVERABILITY</h2>
          <p>
            To the extent permitted by applicable law, the parties waive all legal provisions that would cause a clause of this agreement 
            to be invalid or otherwise unenforceable in any respect. In the event that any provision of this agreement is nevertheless 
            invalid or otherwise unenforceable, such provision shall be interpreted in a manner that comes as close as possible to the 
            purpose intended by that provision (to the greatest extent permitted by applicable law), while the remaining provisions of 
            this Agreement shall remain in force.
          </p>

          <h2>OTHER LEGAL DOCUMENTS</h2>
          <p>
            Privacy Policy (Visitor/Cardholder) WAF Seller Agreement and Terms of Service (Event Organizers).
          </p>
        `,
      };

    case "sixtix":
      return {
        ...defaultData,
      };

    case "france":
      return {
        ...defaultData,
        theme: "france",
        title: "Fancee",
        defaultTimeZone: "Europe/Paris",
        currency: "EUR",
        terms: `
          <p className="terms-title" style="text-align: center">INFORMATIONS LEGALES</p>
          <p style="margin-left:0px;">
            Conformément aux dispositions des articles 6-III et 19 de la loi pour la Confiance dans l'Économie Numérique, nous vous informons que:&nbsp;
          </p>
          <p style="margin-left:0px;">
            Le présent site internet, www.france.fr (ci-après désigné le «Site ») est édité par :&nbsp;
          </p>
          <p style="margin-left:0px;">
            Le GIE Atout France,&nbsp;<br>dont le siège social est situé au 200/216 rue Raymond Losserand - CS 60043 - 75680 PARIS Cedex 14&nbsp;<br>Tél. : +33 1 42 96 70 00&nbsp;<br>RCS de Paris 340 709 211&nbsp;<br>Numéro de TVA intracommunautaire : FR 39340709211&nbsp;
          </p>
          <p style="margin-left:0px;">
            Adresse de courrier électronique : legal@atout-france.fr.&nbsp;
          </p>
          <p style="margin-left:0px;">
            La directrice de la publication du Site est la Directrice Générale de Atout France, Mme Rose-Marie Abel.
          </p>
          <p style="margin-left:0px;">
            Le Site est hébergé par :&nbsp;
            <br>
            <br>
            SCALEWAY
            <br>
            <span style="background-color:rgb(255,255,255);color:rgb(0,0,0);">
              dont le siège social est situé au 11bis rue Roquépine, 75008 Paris
            </span>
            <br>
            Tél. : &nbsp;01 53 77 61 77
            <br>
            <br>
            <span style="background-color:rgb(255,255,255);color:rgb(0,0,0);">
              <strong>Propriété intellectuelle&nbsp;</strong>
            </span>
            <br>
            L'ensemble de ce Site relève de la législation française notamment en matière de propriété intellectuelle et de données personnelles ainsi que de la législation internationale.&nbsp;
          </p>
          <p style="margin-left:0px;">
            Les informations, pictogrammes, iconogrammes, photographies, images, textes, séquences vidéo, animées sonores ou non, et autres éléments présents sur le Site sont protégés par des droits de propriété industrielle et/ou intellectuelle dont Atout France, est soit titulaire, soit autorisé à les reproduire et les représenter.&nbsp;
          </p>
          <p style="margin-left:0px;">
            A ce titre, toute reproduction, représentation, adaptation, traduction et/ou modification, partielle ou intégrale ou transfert par quelque procédé que ce soit, est interdite sans autorisation expresse, préalable et écrite de Atout France. Cet acte constituerait une contrefaçon au sens des articles L.335-2 et suivants du Code de la Propriété intellectuelle.&nbsp;
          </p>
          <p style="margin-left:0px;">
            La copie sur support papier à usage privé de ces différents objets de droits est autorisée conformément à l’article L122-5 du Code de la Propriété Intellectuelle.&nbsp;
          </p>
          <p style="margin-left:0px;">
            Pour toute utilisation ou renseignement à ce sujet, veuillez nous contacter en vous adressant à :&nbsp;
          </p>
          <p style="margin-left:0px;">
            Atout France, Secrétariat Général,&nbsp;
          </p>
          <p style="margin-left:0px;">
            200/216 rue Raymond Losserand - CS 60043 - 75680 PARIS Cedex 14&nbsp;
          </p>
          <p style="margin-left:0px;">
            ou en ligne à l'adresse suivante : legal@atout-france.fr.&nbsp;
          </p>
          <p style="margin-left:0px;">
            Les marques de Atout France et de ses partenaires, ainsi que les logos cités et reproduits sur ce Site sont des marques déposées.&nbsp;
          </p>
          <p style="margin-left:0px;">
            Toute reproduction totale ou partielle de ces marques ou de ces logos effectués à partir des éléments du Site sans l'autorisation expresse et préalable de Atout France est prohibée au sens du Code de la Propriété intellectuelle.&nbsp;
          </p>
          <p style="margin-left:0px;">
            Atout France ne saurait être responsable de l'accès par les internautes via les liens hypertextes mis en place dans le cadre du Site en direction d'autres ressources présentes sur le réseau.&nbsp;
          </p>
          <p style="margin-left:0px;">
            <strong>Protection des données personnelles&nbsp;</strong>
          </p>
          <p style="margin-left:0px;">
            Le respect de la protection des données à caractère personnel est un élément déterminant de la relation de confiance que nous souhaitons établir avec vous.&nbsp;
          </p>
          <p style="margin-left:0px;">
            Que vous soyez visiteur, partenaire, adhérent, client, lecteur ou prospect, usager d’une des missions de service public administratif, professionnel ou particulier, Atout France collecte et traite vos données à caractère personnel pour :&nbsp;
          </p>
          <p style="margin-left:0px;">
            vous informer sur nos événements et ceux de nos partenaires et vous permettre d’y participer ;&nbsp;
          </p>
          <p style="margin-left:0px;">
            vous adresser nos communications (newsletter, catalogues, etc.) ;&nbsp;
          </p>
          <p style="margin-left:0px;">
            vous proposer nos produits et nos services ou vous faire connaitre ceux de nos partenaires;&nbsp;
          </p>
          <p style="margin-left:0px;">
            vous permettre d’accéder à notre site internet et de participer à nos jeux concours ;&nbsp;
          </p>
          <p style="margin-left:0px;">
            nous permettre de faire des analyses et des études relatives à nos produits et services et à la fréquentation de nos sites internet ;&nbsp;
          </p>
          <p style="margin-left:0px;">
            nous permettre de réaliser les missions qui nous sont confiées.&nbsp;
          </p>
          <p style="margin-left:0px;">
            Des données indirectement personnelles, à usage statistique, peuvent également être collectées à des fins de gestion de votre connexion et de votre navigation. Vous pouvez consulter notre politique sur les cookies en suivant le lien ci-dessous :&nbsp;
          </p>
          <p style="margin-left:0px;">
            <a href="https://www.france.fr/fr/page/informations-legales">
              <span style="color:rgb(0,0,255);">
                https://www.france.fr/fr/page/informations-legales
              </span>
            </a>&nbsp;
          </p>
          <p style="margin-left:0px;">
            Nous ne mettons en œuvre des traitements de données que lorsque l’une des conditions suivantes est remplie :&nbsp;
          </p>
          <p style="margin-left:0px;">
            votre consentement aux opérations de traitement a été recueilli ;&nbsp;
          </p>
          <p style="margin-left:0px;">
            l’existence de notre intérêt légitime, ou de celui d’un tiers, qui justifie que nous mettions en œuvre le traitement de données à caractère personnel concerné ;&nbsp;
          </p>
          <p style="margin-left:0px;">
            l’exécution d’un contrat qui nous lie à vous nécessite que nous mettions en œuvre le traitement de données à caractère personnel concerné ;&nbsp;
          </p>
          <p style="margin-left:0px;">
            nous sommes tenus par des obligations légales et réglementaires qui nécessitent la mise en œuvre du traitement de données à caractère personnel concerné.&nbsp;
          </p>
          <p style="margin-left:0px;">
            Dans l’hypothèse où nous sollicitons votre consentement, celui-ci est collecté directement sur le formulaire prévu pour procéder à la collecte des informations qui vous concerne.&nbsp;
          </p>
          <p style="margin-left:0px;">
            <strong>Comment exercer vos droits&nbsp;</strong>
          </p>
          <p style="margin-left:0px;">
            Vous pouvez exercer vos droits (demande d’accès, de rectification, d’oubli, d’effacement, d’opposition ou de portabilité, plus de précisions sous 
            <a href="https://assets.france.fr/int/legal/general/ATF-formulaire-de-demande-d-exercice-des-droits-des-personnes-concernees.pdf" target="_blank" rel="noopener noreferrer">
              ce lien
            </a>
             par voie électronique à l’adresse suivante : contact.rgpd@atout-france.fr, ou par courrier postal à l’adresse suivante : Atout France, Service juridique, 200/216 rue Raymond Losserand - CS 60043 - 75680 PARIS Cedex 14 en justifiant de votre identité.&nbsp;
          </p>
          <p style="margin-left:0px;">
            Vous pouvez introduire une réclamation auprès d’une autorité de contrôle – En France, il s’agit de la CNIL, 3 place de Fontenoy, TSA 80715, 75334 Paris cedex 07.&nbsp;
          </p>
          <p style="margin-left:0px;">
            Vous avez la possibilité de définir des directives relatives à la conservation, à l’effacement et à la communication de vos données personnelles après votre décès et ce auprès d’un tiers de confiance, certifié et chargé de faire respecter la volonté du défunt, conformément aux exigences du cadre juridique applicable.&nbsp;
          </p>
          <p style="margin-left:0px;">
            <strong>Mentions d’informations détaillées&nbsp;</strong>
          </p>
          <p style="margin-left:0px;">
            Vous trouverez plus d’informations sur le traitement de vos données personnelles et sur vos droits en cliquant sur le lien suivant :&nbsp;
          </p>
          <p style="margin-left:0px;">
            Vous êtes un usager de service public administratif : vos mentions d’informations sous 
            <a href="https://assets.france.fr/int/legal/admin/Mentions_informations-Atout-France-Usagers-service-public.pdf" target="_blank" rel="noopener noreferrer">
              <span style="color:rgb(0,0,255);">ce lien</span>
            </a> ;&nbsp;
          </p>
          <p style="margin-left:0px;">
            Vous êtes un partenaire-client-prospect de Atout France : vos mentions d’informations sous 
            <a href="https://assets.france.fr/int/legal/partenaires/NOTICE-D-INFORMATION-POUR-LES-PARTENAIRES-CLIENTS-OU-PROSPECTS.pdf" target="_blank" rel="noopener noreferrer">
              ce lien
            </a>.&nbsp;
          </p>
          <p style="margin-left:0px;">
            Vous pouvez également nous contacter à l’adresse suivante :&nbsp;
          </p>
          <p style="margin-left:0px;">
            contact.rgpd@atout-france.fr ou par courrier postal à Atout France - Service Juridique – 200/216 rue Raymond Losserand - CS 60043 - 75680 PARIS Cedex 14 – France, Votre demande d’exercice de droits devra être accompagnée d’une copie d’un titre d’identité signé afin de nous permettre de vérifier votre identité.&nbsp;
          </p>
          <p style="margin-left:0px;">
            <strong>Limitation de responsabilité&nbsp;</strong>
          </p>
          <p style="margin-left:0px;">
            Atout France ne saurait être tenue pour responsable des erreurs matérielles qui se seraient glissées dans les documents présents sur le Site, malgré tout le soin apporté à leur publication.&nbsp;
          </p>
          <p style="margin-left:0px;">
            Les informations fournies sur le Site le sont à titre indicatif et ne sauraient dispenser l'internaute d'une analyse complémentaire et personnalisée.&nbsp;
          </p>
          <p style="margin-left:0px;">
            Atout France ne saurait non plus être tenue pour responsable des informations, propos et opinions émises sur les sites vers lesquels elle pointe par des liens hypertexte et dont elle n'a pas la maîtrise éditoriale.&nbsp;
          </p>
          <p style="margin-left:0px;">
            Les sites qui font le choix de pointer vers france.fr (<a href="https://www.france.fr/"><span style="color:rgb(0,0,255);">https://www.france.fr</span></a>) engagent leur responsabilité dès lors qu’ils porteraient atteinte à l’image du Site.&nbsp;
          </p>
          <p style="margin-left:0px;">
            Atout France ne peut pas plus être tenue pour responsable de la transmission défectueuse des données dues aux divers réseaux de l’Internet ou aux incompatibilités dues au navigateur de l’internaute.&nbsp;
          </p>
          <p style="margin-left:0px;">
            Tout litige en relation avec l'utilisation du Site est soumis au droit français. L'internaute reconnaît la compétence exclusive des tribunaux compétents du ressort de la Cour d’appel de Paris.&nbsp;<br>&nbsp;
          </p>
        `
      }

    default:
      localStorage.setItem("region", "sixtix"); // dev feature

      return {
        ...defaultData
      }
  }
}

function getDefinedRegion() {
  const host = window.location.host;

  switch (host) {
    case "localhost:3000":
    case "dev-app.sixtix.com":
    case "app.sixtix.com":
      return "sixtix";

    case "dev-app.fanceetickets.com":
    case "app.fanceetickets.com":
      return "sixtix";
      // return "fanceetickets";

    case "dev-app.headworks.com.ua":
    case "app.headworks.com.ua":
    case "dev-app.france.fr":
    case "app.france.fr":
      return "france";

    default:
      break;
  }
}