import React from "react";
import ReactTable from "react-table";

import "./PointsHistoryModal.sass";
import { pointsHistoryReasons } from "../../../constants/pointsHistoryReasons";

export const PointsHistoryModal = ({ pointsHistory }) => {
  const getLabelStyle = () => ({
    style: {
      marginTop: '6px',
    },
  });
  const columns = [
    {
      Header: "#",
      width: 50,
      Cell: ({ viewIndex}) => {
        return (
          <span>{ viewIndex + 1 }</span>
        )
      },
      getProps: getLabelStyle,
    },
    {
      Header: "Date&Time",
      width: 200,
      Cell: ({ row: { _original: { createdAtUtc } } }) => {
        const date = new Date(createdAtUtc);
        
        return <>{
          date.toLocaleDateString()
        } {date.toLocaleTimeString()}</>
      },
      getProps: getLabelStyle,
    },
    {
      Header: "Point Changes",
      accessor: "pointChanges",
      width: 120,
      getProps: getLabelStyle,
    },
    {
      Header: "Reason",
      width: 120,
      Cell: ({ row: { _original: { reason } } }) => {
        const label = pointsHistoryReasons.find(r => r.value === reason).label || "N/A"
        
        return <span>{label}</span>
      },
      getProps: getLabelStyle,
    },
  ];

  return (
    <section className="history-modal">
      <ReactTable
        data={pointsHistory}
        columns={columns}
        defaultPageSize={100000}
        showPagination={false}
        minRows={0}
        sortable={false}
        resizable={false}
      />
    </section>
  )
}