import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import ROUTES from "../../../../../constants/routes";
import Icon from "../../../../../components/UI/Icon/Icon";
import NewLoader from "../../../../../components/NewLoader/NewLoader";
import MobileTicketsList from "../../../../../components/Events/MobileTicketsList";

import "./EventTickets.sass";


class EventTickets extends Component {
  state = {
    isImportActive: false,
    ticketToSync: null,
  };

  goToTicketPage = (id, event) => {
    if (
      event.target.tagName.toLowerCase() !== "button" &&
      (event.target.getAttribute("class") === "event__list__item" ||
        event.target.getAttribute("class") === "event__list__item__title" ||
        event.target.getAttribute("class") === "rt-td" ||
        event.target.tagName.toLowerCase() === "span")
    )
      this.props.history.push({
        pathname: ROUTES.NEW_TICKET_DETAIL.replace(":ticket_id", id).replace(
          ":id",
          this.props.match.params.id
        ),
      });
  };

  importTicketsTogler = (data = null) => {
    this.setState((prevState) => ({
      ticketToSync: data,
      isImportActive: !prevState.isImportActive,
    }));
  };

  goToUploadBarcodes = (data) => {
    this.props.history.push({
      pathname: ROUTES.NEW_ADD_BAR_CODE.replace(
        ":id",
        this.props.match.params.id
      ).replace(":ticket_id", data.original.id),
    });
  };

  goToAddTicket = () => {
    this.props.history.push({
      pathname: ROUTES.NEW_ADD_TICKET.replace(":id", this.props.match.params.id),
    });
  };

  render() {
    const { currentEvent } = this.props;
    let emptyStateText = "There are no tickets";
    const classes = [];

    if (
      currentEvent.ticketTemplates &&
      currentEvent.ticketTemplates.length > 6
    ) {
      classes.push("scroll");
    };

    const getLabelStyle = () => ({
      style: {
        marginTop: '6px',
      },
    });

    const getFlexStyle = () => ({
      style: {
        marginTop: "3px",
        padding: "0 5px",
        display: "flex",
        alignItems: "center",
      },
    });

    const columns = [
      {
        Header: "Name",
        accessor: "ticketName",
        Cell: ({
          row: {
            _original: { ticketName, id },
          },
        }) => (
        <span title={ticketName} >
          {ticketName}
        </span>),
        getProps: getLabelStyle,
      },
      {
        Header: "Scanned",
        accessor: "scannedTickets",
        width: 120,
        getProps: getLabelStyle,
      },
      {
        Header: "Total",
        accessor: "allTickets",
        width: 120,
        getProps: getLabelStyle,
      },
      {
        Header: "Price",
        accessor: "price",
        width: 120,
        getProps: getLabelStyle,
      },
      {
        Header: "Capacity",
        accessor: "capacity",
        width: 120,
        getProps: getLabelStyle,
      },
      {
        Header: () => (
          <div className="link-stats">
            {null}
          </div>
        ),
        id: "clicka-me-button",
        Cell: (data) => (
          <>
            <button
              className="btn-primary btn-primary--barcode"
              onClick={() => {
                this.goToUploadBarcodes(data);
              }}
            >
              Upload barcodes
            </button>
          </>
        ),
        width: 200,
        getProps: getFlexStyle,
      },
    ];

    return (
      <div className="new-event__tickets tickets">
        {currentEvent.ticketTemplates === undefined ? (
          <div></div>
        ) : currentEvent.ticketTemplates.length === 0 ? (
          <div className="empty-block">
            <p className="empty-block__text">{emptyStateText}</p>
            <img src="/images/img-empty-events.svg" alt="Empty events" />
          </div>
        ) : (
          <Fragment>
            <ReactTable
              className={classes.join(" ")}
              data={currentEvent.ticketTemplates}
              columns={columns}
              defaultPageSize={100000}
              showPagination={false}
              minRows={0}
              sortable={false}
              resizable={false}
              getTrProps={(state, rowInfo) => ({
                onClick: (e) => {
                  this.goToTicketPage(rowInfo.original.id, e);
                },
              })}
            />
            <MobileTicketsList
              list={currentEvent.ticketTemplates}
              onRowClick={this.goToTicketPage}
              onImportClick={(ticketId) => this.importTicketsTogler(ticketId)}
            />
          </Fragment>
        )}

          <div className="tickets__buttons">
            <button
              type="button"
              className="btn-primary tickets__add-button"
              onClick={this.goToAddTicket}
            >
              <Icon name="plus" />
              <span>Add ticket</span>
            </button>
          </div>
      </div>
    );
  }
};

const mapStateToProps = ({
  events: { currentEvent },
}) => ({ currentEvent });

export default connect(mapStateToProps)(EventTickets);
