import React, { useEffect, useState } from "react";
import Select from "react-select";
import { SeasonPassFieldLabel } from "../../SeasonPass/SeasonPassFieldLabel";
import { SelectedTickets } from "../SelectedTickets";

import { paymentMethodOptions as defaultOptions } from "../../SeasonPass/constants";
import { isValidEmail } from "../../../helpers/validators";

import "../OpenSell.sass";
import { getCurrentLanguageTexts } from "../../../constants/seasonPassPersonalInfoLangs";

export const ChoosePaymentMethod = ({
  tickets,
  setCouponHandler,
  coupon,
  total,
  buyerEmail,
  handleRepaetEmailChange,
  reapetedBuyerEmail,
  email,
  hasSeats,
  totalFee,
  currency,
  paymentMethod,
  paymentMethodOptions = defaultOptions,
  handleChange = () => { },
  allowEnterCouponCode = true,
}) => {

  const txt = getCurrentLanguageTexts();

  const isEmailsNoMatch =
    reapetedBuyerEmail && reapetedBuyerEmail !== buyerEmail;

  const [inputReapetedEmail, setInputReapetedEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);

  const changeInputEmailHandler = (value) => {
    setInputReapetedEmail(value);
  };

  const handleClick = () => {
    handleChange("buyerEmail", reapetedBuyerEmail);
  };

  useEffect(() => {
    setValidEmail(
      isValidEmail(inputReapetedEmail) || inputReapetedEmail === ""
    );
  }, [inputReapetedEmail]);

  return (
    <div className="partizan-open-selling__step">
      <div className="partizan-open-selling__step-hero">
        <h4 className="partizan-open-selling__text-bold">{txt.choosePaymentMethodTxt["Ticket receipt"]}</h4>
        <p
          style={{ marginBottom: "20px" }}
          className="partizan-open-selling__text-normal-small"
        >
          {txt.choosePaymentMethodTxt["You will receive tickets at"]}{" "}
          <span className="partizan-open-selling__text-bold-small">
            {email}
          </span>
        </p>

        <SeasonPassFieldLabel
          shouldWrapInLabel={false}
          label={txt.choosePaymentMethodTxt["Please repeat your email address"]}
          isRequired={!reapetedBuyerEmail}
        >
          <div
            style={{ marginBottom: "10px" }}
            className="partizan-open-selling__validation-fields-group"
          >
            <div className="block-input-validation">
              <input
                className="input-field-validation"
                placeholder={txt.choosePaymentMethodTxt["Enter your email"]}
                onChange={(e) => {
                  handleRepaetEmailChange(e.target.value);
                  changeInputEmailHandler(e.target.value);
                }}
                type="text"
              />
              {!validEmail && (
                <span className="input-valid-error">
                  {txt.choosePaymentMethodTxt["This is an invalid email"]}
                </span>
              )}
            </div>

            {isEmailsNoMatch && validEmail && (
              <button onClick={handleClick} className="btn-primary">
                {txt.choosePaymentMethodTxt["Use this email"]}
              </button>
            )}
          </div>

          {isEmailsNoMatch && (
            <span className="partizan-open-selling__text-normal-small partizan-open-selling__text-error">
              {txt.choosePaymentMethodTxt["Email Error"]}
            </span>
          )}
        </SeasonPassFieldLabel>

        <SeasonPassFieldLabel
          label={txt.choosePaymentMethodTxt["Payment method"]}
          isRequired={!paymentMethod}
          className="partizan-open-selling__payment-method"
        >
          <Select
            options={paymentMethodOptions}
            name="paymentMethod"
            value={paymentMethodOptions.find(
              ({ value }) => paymentMethod.id === value.id
            )}
            onChange={(value) => handleChange("paymentMethod", value)}
            classNamePrefix="custom-select"
            className="partizan-open-selling__select"
          />
        </SeasonPassFieldLabel>
      </div>
      <div className="partizan-open-selling__step-info">
        <SelectedTickets
          tickets={tickets}
          hasSeats={hasSeats}
          currency={currency}
          total={total}
          totalFee={totalFee}
          setCouponHandler={setCouponHandler}
          coupon={coupon}
          allowEnterCouponCode={allowEnterCouponCode}
        />
      </div>
    </div>
  );
};
