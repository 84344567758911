export const currencies = [
  { value: 1, label: "USD" },
  { value: 4, label: "EUR" },
  { value: 5, label: "RSD" },
];

export const getCurrencieLabel = (id, deflt = 'RSD') => {
  return getCurrencieObject(id).label || deflt
}

export const getCurrencieObject = (id) => {
  return currencies.find(({ value }) => value === id)
}
