import hexToRgba from "../../../helpers/hexToRgba";

export default function renderEllipse({
  context,
  x,
  y,
  width,
  height,
  backgroundColorHex,
  strokeColorHex,
  strokeWidth,
  opacity,
}) {
  context.save();
  context.fillStyle = hexToRgba({ hex: backgroundColorHex, opacity });
  context.strokeStyle = hexToRgba({
    hex: strokeColorHex,
    opacity: strokeWidth ? opacity : 0,
  });
  context.lineWidth = strokeWidth;
  context.beginPath();

  const absWidth = Math.abs(width);
  const absHeight = Math.abs(height);

  context.ellipse(
    x + absWidth / 2,
    y + absHeight / 2,
    absWidth / 2,
    absHeight / 2,
    0,
    0,
    2 * Math.PI
  );
  context.fill();
  context.stroke();
  context.restore();
}
