import React from "react";

import { paymentStatuses } from "../../../constants/paymentStatuses";
import { Tooltip } from "../../UI/Tooltip/Tooltip";

import "./StatusLabel.sass";

export const StatusLabel = ({
  statusValue,
  description,
  statuses = paymentStatuses,
  text,
  ...props
}) => {
  const status = statuses.find(
    (paymentStatus) => paymentStatus.value === statusValue
  ) || { label: statusValue };

  let className = `StatusLabel StatusLabel__${`${status.label}`.replaceAll(
    " ",
    "-"
  )}`;

  if (props.className) {
    className += ` ${props.className}`
  }

  return (
    <Tooltip className={className} description={description}>
      <span className="StatusLabel__Text">{text ? text : status.label}</span>
    </Tooltip>
  );
};
