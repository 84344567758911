import React, {Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import Registration from '../../containers/Auth/Registration/Registration';
import RegistrationSuccess from '../../containers/Auth/Registration/RegistrationSuccess';
import Login from '../../containers/Auth/Login/Login';
import RecoverPassword from '../../containers/Auth/RecoverPassword/RecoverPassword';
import NewPassword from '../../containers/Auth/RecoverPassword/NewPassword';
import Page404 from "../../components/Page404/Page404";

const NotAuthorizedLayout = () => {
  const path = window.location.pathname;
  const isPath = path === ROUTES.REGISTRATION ||
    path === ROUTES.REGISTRATION_SUCCESS ||
    path === ROUTES.LOGIN ||
    path === ROUTES.RECOVER_PASSWORD ||
    path === ROUTES.NEW_PASSWORD;

  if (localStorage.getItem('user')) {
    return <Redirect to={ROUTES.EVENTS} />
  }

  if (isPath) {
    return (
      <Fragment>
        <Route path={ROUTES.REGISTRATION} component={Registration} exact />
        <Route path={ROUTES.REGISTRATION_SUCCESS} component={RegistrationSuccess} exact/>
        <Route path={ROUTES.LOGIN} component={Login} exact/>
        <Route path={ROUTES.RECOVER_PASSWORD} component={RecoverPassword} exact/>
        <Route path={ROUTES.NEW_PASSWORD} component={NewPassword}/>
      </Fragment>
    )
  }

  if (window.location.pathname === "/") {
    return <Redirect from="/" exact to={ROUTES.LOGIN} />
  }
  

  return <Route component={Page404}/>
};

export default NotAuthorizedLayout;
