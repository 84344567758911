import React, { Component } from 'react';
import NavigationTabs from '../../components/NavigationTabs/PromoterNavigationTabs';
import PageTitle from '../../components/UI/PageTitle/PageTitle';
import NewLoader from '../../components/NewLoader/NewLoader';
import ROUTES from '../../constants/routes';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';

import EmailTemplates from './PromoterPages/EmailTemplates/EmailTemplates';
import PromoterInfo from './PromoterPages/Info/PromoterInfo';
import BalancePage from './PromoterPages/BalancePage/BalancePage';

import './Promoter.sass';

class Promoter extends Component {
  /* constructor(props) {
    super(props);

    this.state = {
      currentEvent: {},
      isFound: true,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.currentEvent.id &&
      props.currentEvent.id !== state.currentEvent.id
    ) {
      const currentEvent = {
        ...props.currentEvent,
      };
      return {
        currentEvent,
      };
    }
    return null;
  } */

  /* componentDidMount() {
    const { path } = this.props.match;
    const action = queryString.parse(this.props.location.search).action;

    if (path !== ROUTES.NEW_ADD_EVENT) {
      this.props
        .getEvent(this.props.match.params.id, this.props.user.token, action)
        .then((result) => {
          if (!result.isFound) {
            this.setState({ isFound: false });
          }
        });
    };
  }; */

  render() {
    return (
      <section className='promoter'>
        <div className='promoter__wrapper'>
          <div className='promoter__nav'>
            <NavigationTabs />
          </div>

          <div className='promoter__container'>
            {this.props.loading && (
              <div className='promoter__loader'>
                <NewLoader />
              </div>
            )}

            <Switch>
              <Redirect
                from={ROUTES.PROMOTER}
                to={ROUTES.PROMOTER_INFO}
                exact
              />

              <Route path={ROUTES.PROMOTER_INFO} exact component={PromoterInfo} />
              <Route path={ROUTES.PROMOTER_TEMPLATES} exact component={EmailTemplates} />
              <Route path={ROUTES.PROMOTER_BALANCE} exact component={BalancePage} />
            </Switch>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({
  auth: { user },
  events: { currentEvent, loading, error, currentTicket },
}) => ({ user, currentEvent, loading, error, currentTicket });

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Promoter));
