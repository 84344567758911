import React, { useState } from 'react';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Select from "react-select";

import CheckBox from '../../../../components/UI/Checkbox/CheckBox';
import { SelectedTickets } from '../../../OpenSell/SelectedTickets';
import { companyCountryOptions } from "../../constants";
import { getPrice, getTotalFee } from "../../../../helpers/ticketPriceHelper.ts";

import { getCurrentLanguageTexts } from '../../../../constants/seasonPassPersonalInfoLangs';
import { getSeatLabel, entreeTicketToSeatsIointerface } from '../../../../helpers/ticketHelper.ts';
import TermsAndConditionsModal from "../../TermsAndConditionsModal/TermsAndConditionsModal.js";

const MySwal = withReactContent(Swal);

export const BuySeasonPassModal = ({
    membershipCard,
    ticketTemplate,
    event,
    onConfirmBuySeasonPass,
    availablePoints}) => {
    const txt = getCurrentLanguageTexts();
    const [seatLabel, setSeatLabel] = useState(getSeatLabel(membershipCard));
    const [cardSelected, setCardSelected] = useState(false);
    const [companyVatNumber, setCompanyVatNumber] = useState("");
    const [companyCountry, setCompanyCountry] = useState(
      txt.BuySeasonPassModalTxt.companyCountryOptions[0].value
    );
    const [isTermsChecked, setTermsChecked] = useState(false);

    const nfcCardTicket = event.ticketTemplates.find(t => t.ticketTemplateId == process.env.REACT_APP_NEW_SEASON_NFC_CARD_TICKET_TEMPLATE_ID);
    const tickets = [entreeTicketToSeatsIointerface(membershipCard, ticketTemplate)];

    if (cardSelected) {
        if (nfcCardTicket) {
            tickets.push(entreeTicketToSeatsIointerface({}, nfcCardTicket));
        }
    }

    let totalPrice = tickets.reduce((a, v) => a + v.price, 0)

    let pointsToDiscount = (totalPrice / 2).toFixed(0);

    if (pointsToDiscount > availablePoints) {
        pointsToDiscount = availablePoints;
    }

    totalPrice = ((totalPrice - pointsToDiscount) * (1 + event.feeRate) +  event.fixedFeeRate).toFixed(2);

    const confirmHandler = () => {
        const seats = [{
            ticketName: ticketTemplate.name,
            area: membershipCard.area,
            side: membershipCard.side,
            row: membershipCard.row,
            seat: membershipCard.seat,
            ticketTemplateId: ticketTemplate.ticketTemplateId,
            amount: (ticketTemplate.price * (1 + event.feeRate)).toFixed(2),
        }];

        if (cardSelected) {
            seats.push(
                {
                    ticketName: nfcCardTicket.name,
                    ticketTemplateId: nfcCardTicket.ticketTemplateId,
                    area: membershipCard.area,
                    side: membershipCard.side,
                    row: membershipCard.row,
                    seat: membershipCard.seat,
                    amount: (nfcCardTicket.price * (1 + event.feeRate)).toFixed(2),
                })
        }

        MySwal.close();
        onConfirmBuySeasonPass(seats, membershipCard.customerName, totalPrice, pointsToDiscount, { companyVat: companyVatNumber, companyCountry: companyCountry });
    };

    const handleOnBuyNfcChange = () => {
        setCardSelected(!cardSelected);
    }

    const changeInputCompanyVatHandler = (e) => {
        setCompanyVatNumber(e.target.value);
    }

    const changeCompanyCountryHandler = (e) => {
        setCompanyCountry(e.value);
    }

    const showTermsAndConditionsModal = (ev) => {
      ev.preventDefault();
      MySwal.fire({
        html: <TermsAndConditionsModal />,
        width: "64em",
        customClass: "seasonpass__modal partizan-info-modal partizan-modal",
      });
    };

    const handleTermsChangeCheckbox = () => {
      setTermsChecked(!isTermsChecked);
    }

    const totalFee = getTotalFee(tickets, event.fixedFeeRate, event.feeRate, pointsToDiscount);

    return <>
        <section className="buy-sp-modal">
            <p><strong>{seatLabel}</strong></p>
            <p>{txt.BuySeasonPassModalTxt['NFCDescription']}</p>
            <CheckBox
                name="buyNfcCard"
                id="buy-nfc-card"
                checked={cardSelected}
                onChange={handleOnBuyNfcChange}>
                {txt.BuySeasonPassModalTxt['buyPhysicalNFCCard']}
            </CheckBox>

            <section style={{ marginBottom: "20px" }}>

                <SelectedTickets
                    hasSeats={true}
                    mixedTickets={true}
                    totalFee={totalFee}
                    tickets={tickets}
                    currency={'RSD'}
                    total={totalPrice}
                    allowEnterCouponCode={false}
                    autoHeight={true}
                    pointsForDiscount={pointsToDiscount}
                />
                <input
                    type="text"
                    name="companyVatNumber"
                    className="buy-sp-modal__vat-number"
                    placeholder={txt.BuySeasonPassModalTxt['vatPlaceholder']}
                    value={companyVatNumber}
                    onChange={changeInputCompanyVatHandler}
                />
                <Select
                    options={txt.BuySeasonPassModalTxt.companyCountryOptions}
                    name="companyCountry"
                    value={txt.BuySeasonPassModalTxt.companyCountryOptions.find(
                        ({ value }) => companyCountry === value
                    )}
                    onChange={changeCompanyCountryHandler}
                    classNamePrefix="seasonpass__select partizan-open-selling__select"
                    className="seasonpass__select partizan-open-selling__select buy-sp-modal__select"
                />
            </section>

            <CheckBox
                checked={isTermsChecked}
                id="terms"
                name="terms"
                onChange={handleTermsChangeCheckbox}
              >
                <span className="partizan-open-selling__text-medium-small">
                  {txt.generalTxt['agreement']}
                  <span
                    className="partizan-open-selling__terms-of-service-text"
                    onClick={showTermsAndConditionsModal}
                  >
                    {txt.generalTxt['terms']}
                  </span>
                </span>
              </CheckBox>

            <section className='swal2-actions'>
                <button className="swal2-cancel swal2-styled"
                    onClick={() => MySwal.close()} >
                    {txt.generalTxt['cancel']}
                </button>
                <button
                  className="swal2-confirm swal2-styled"
                  onClick={confirmHandler}
                  disabled={!isTermsChecked}
                >
                    {txt.generalTxt['confirm']}
                </button>

            </section>

        </section>
    </>
}