import React from "react";
import Select from "react-select";

import { paymentTypeOptions } from "../../../../../SeasonPass/constants";

import { SelectedTickets } from "../../../../../OpenSell/SelectedTickets";

import { SeasonPassFieldLabel } from "../../../../../SeasonPass/SeasonPassFieldLabel";

export const ChoosePaymentMethod = ({
  tickets,
  total,
  totalFee,
  paymentType,
  email,
  hasSeats,
  currency,
  handleChange = () => {},
  fields,
  allowEnterCouponCode = true,
}) => {
  return (
    <div className="partizan-open-selling__step">
      <div className="partizan-open-selling__step-hero">
        <h4 className="partizan-open-selling__text-bold">Ticket receipt</h4>
        <p className="partizan-open-selling__text-normal-small">
          You will receive tickets at{" "}
          <span className="partizan-open-selling__text-bold-small">
            {email}
          </span>
        </p>

        <SeasonPassFieldLabel
          label={"Payment method"}
          isRequired={!paymentType}
          className="partizan-open-selling__payment-method"
        >
          <Select
            options={paymentTypeOptions}
            name="paymentType"
            value={paymentTypeOptions.find(
              ({ value }) => paymentType === value
            )}
            onChange={(value) => handleChange("paymentType", value.value)}
            classNamePrefix="custom-select"
            className="partizan-open-selling__select"
          />
        </SeasonPassFieldLabel>
        {paymentType === 4 && (
          <SeasonPassFieldLabel
            isRequired={!fields.generationTitle}
            label="Generation Title"
          >
            <input
              className="seasonpass__field-input"
              placeholder="Enter Generation Title"
              name="generationTitle"
              onChange={(e) => handleChange("generationTitle", e.target.value)}
              type="text"
              value={fields.generationTitle}
            />
          </SeasonPassFieldLabel>
        )}
      </div>
      <div className="partizan-open-selling__step-info">
        <SelectedTickets
          tickets={tickets}
          hasSeats={hasSeats}
          currency={currency}
          total={total}
          totalFee={totalFee}
          allowEnterCouponCode={allowEnterCouponCode}
        />
      </div>
    </div>
  );
};
