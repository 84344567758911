import React, { useState } from "react";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getCurrencieLabel } from "../../../../constants/currencies";
import { SelectTickets } from "../../../OpenSell/Steps";
import { isNoSeatTicket, isSeatsIoTicket } from "../../../../helpers/ticketHelper.ts";
import { getPrice, getTotalFee } from "../../../../helpers/ticketPriceHelper.ts";
import { getTotal } from "../../helpers";
import { executePayment } from "../../../../helpers/paymentHelper/paymentHelper.ts";
import { getCurrentLanguageTexts } from "../../../../constants/seasonPassPersonalInfoLangs.js";
import CheckBox from "../../../../components/UI/Checkbox/CheckBox.js";
import TermsAndConditionsModal from "../../TermsAndConditionsModal/TermsAndConditionsModal.js";

const MySwal = withReactContent(swal);

export const BuyTicketsOnFutureEventModal = ({ event, memberData, availiableSeatsCount, ...props }) => {
    const [state, setState] = useState({
        tickets: [],
        isLoading: false,
    });
    const [isTermsChecked, setTermsChecked] = useState(false);

    const txt = getCurrentLanguageTexts();

    const paymentAmount = getTotal(getPrice(state.tickets, 0), event.feeRate, event.fixedFeeRate * state.tickets.length);

    const confirmHandler = () => {
        setState(v => {
            return {
                ...v,
                isLoading: true,
            }
        });

        executePayment({
            eventData: event,
            paymentMethod: 0, // kopa only
            couponCode: '',
            redirectUrl: `${window.location.href + "&"}`,
            tickets: state.tickets,
            transaction: {
                ...memberData,
            }
        })
    }

    const onTicketAdded = (position) => {
        setState(state => {
            if (isNoSeatTicket(position)) {
                let findedTicket = state.tickets.find(
                    ({ ticketTemplateId }) => ticketTemplateId === position.ticketTemplateId
                );

                if (findedTicket) {
                    return {
                        ...state,
                        tickets: state.tickets.map((ticket) =>
                            ticket.ticketTemplateId === position.ticketTemplateId ? position : ticket
                        ),
                    };
                }
            }
            if (isSeatsIoTicket(position) && !position.pricing) {
                return {
                    ...state,
                }
            }
            const tickets = [...state.tickets, position];

            return {
                ...state,
                tickets
            };
        })
    };

    const onObjectDeselected = (position) => {
        setState(state => {
            if (isNoSeatTicket(position)) {
                return (
                    {
                        ...state,
                        tickets: state.tickets.filter(
                            (item) => item.ticketTemplateId !== position.ticketTemplateId
                        ),
                    }
                );
            }
            else {
                return (
                    {
                        ...state,
                        tickets: state.tickets.filter((item) => item.id !== position.id),
                    }
                );
            }
        })
    };

    const handleTermsChangeCheckbox = () => {
      setTermsChecked(!isTermsChecked);
    }

    const showTermsAndConditionsModal = (ev) => {
      ev.preventDefault();
      MySwal.fire({
        html: <TermsAndConditionsModal />,
        width: "64em",
        customClass: "seasonpass__modal partizan-info-modal partizan-modal",
      });
    };

    const totalFee = getTotalFee(state.tickets, event.fixedFeeRate, event.feeRate);

    return <>
        <section className="seasonpass-pers-info__buy-tickets-modal">

            <SelectTickets
                totalFee={totalFee}
                ticketTemplates={event.ticketTemplates}
                maxSelectedObjects={Math.min(availiableSeatsCount, event.maxSeatsToSelectPerTransaction)}
                pricing={event.ticketTemplates.map(({ categoryKey, price }) => ({
                    category: categoryKey,
                    price,
                }))}
                tickets={state.tickets}
                onObjectSelected={onTicketAdded}
                onObjectDeselected={onObjectDeselected}
                hasSeats={true}
                workspaceKey={event.seatsIoWorkspaceId}
                eventSeatsId={event.seatsIoEventId}
                event={event}
                currency={getCurrencieLabel(event.currencyId)}
                total={paymentAmount}
                popupMode={true}
            />

            <div
              className="seasonpass-pers-info__buy-tickets-terms"
            >
              {!!state.tickets.length && (
                <CheckBox
                  checked={isTermsChecked}
                  id="terms"
                  name="terms"
                  onChange={handleTermsChangeCheckbox}
                >
                  <span className="partizan-open-selling__text-medium-small">
                    {txt.generalTxt['agreement']}
                    <span
                      className="partizan-open-selling__terms-of-service-text"
                      onClick={showTermsAndConditionsModal}
                    >
                      {txt.generalTxt['terms']}
                    </span>
                  </span>
                </CheckBox>
              )}
            </div>

        </section>

        <section className='swal2-actions'>

            <button className="swal2-cancel swal2-styled"
                onClick={() => MySwal.close()}>
                {txt.generalTxt.cancel}
            </button>

            <button disabled={!state.tickets.length || state.isLoading || !isTermsChecked} className="swal2-confirm swal2-styled"
                onClick={confirmHandler}>
                {txt.generalTxt.buy}
            </button>

        </section>

    </>
}
