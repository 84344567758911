import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";

import {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnLink,
  BtnItalic,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  HtmlButton,
  Separator,
  Editor,
  EditorProvider,
  Toolbar,
  createButton,
  createDropdown,
} from 'react-simple-wysiwyg';

import { getPromoterEmailTemplates, editEmailTemplate } from '../../../../store/actions/emailTemplatesActions';
import { getTemplateTextValues } from "../../../../store/actions/canvasActions";
import NewLoader from "../../../../components/NewLoader/NewLoader";
import postFileToServer from "../../../../helpers/postFileToServer";
import Dropzone from "../../../Upload/Dropzone/Dropzone";

const MySwal = withReactContent(Swal);

class EmailTemplates extends Component {
  state = {
    currentTemplate: {
      value: null,
      label: '',
      item: {
        headerImageUrl: null,
        subject: '',
        bodyContent: '',
      }
    },
    isHeaderImageUploading: false,
    templateValues: [{ value: "", label: "" }],
    isEdited: false,
    isDataValid: true,
    color: "",
  }

  colorPickerRef = React.createRef();
  
  static getDerivedStateFromProps(props, state) {
    if (!state.currentTemplate.value && props.promoterTemplates.length > 0) {
      return {
        currentTemplate: { ...props.promoterTemplates[0] },
      };
    }
    return null;
  };

  handleChangeTemplate = (value) => {
    if (this.state.isEdited) {
      MySwal.fire({
        title: "Are you sure you want to select another template?",
        text: "All unsaved changes will be deleted",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Select"
      }).then((result) => {
        if (result.value) {
          this.setState({
            currentTemplate: value,
            isEdited: false,
            isDataValid: true,
          });
        }
      });
    } else {
      this.setState({
        currentTemplate: value,
        isEdited: false,
        isDataValid: true,
      });
    };
  };

  handleChangeTemplateField = (name, value) => {
    const { ...item } = this.state.currentTemplate.item;

    item[name] = value;
    const currentTemplate = { ...this.state.currentTemplate, item };

    this.setState({ currentTemplate, isEdited: true });
  };

  handleBodyContentChange = (e) => {
    this.handleChangeTemplateField('bodyContent', e.target.value);
  }

  handleChangeImage = (files, fieldName) => {
    this.setState({ isHeaderImageUploading: true });

    postFileToServer("/EmailTemplate/Image", files[0])
    .then((response) => {
      const imageUrl = response.data.result;

      this.handleChangeTemplateField(fieldName, imageUrl);
    })
    .catch((e) => console.error(e))
    .finally(() => {
      this.setState({ isHeaderImageUploading: false });
    });
  };

  updateTemplate = async () => {
    const { value, item } = this.state.currentTemplate;
    const { editEmailTemplate } = this.props;

    if (!item.subject) {
      this.setState({ isDataValid: false });

      return;
    };

    try {
      await editEmailTemplate(value, item, this.state.eventId);

      this.setState({
        isEdited: false,
      });
    } catch (err) {
      toast.error("Template not updated");
    };
  };

  componentDidMount() {
    const { getPromoterEmailTemplates, getTemplateTextValues } = this.props;

    getPromoterEmailTemplates();
    getTemplateTextValues()
      .then((values) => {
        this.setState({ templateValues: values })
      })
      .catch(() => {
        toast.error("Template variables not defined");
      });
  };

  componentDidUpdate() {
    if (
      !!this.state.currentTemplate.item.subject &&
      !this.state.isDataValid
    ) {
      this.setState({ isDataValid: true });
    }
  };

  render() {
    const { currentTemplate, isDataValid, templateValues, isHeaderImageUploading } = this.state;
    const { headerImageUrl, subject, bodyContent } = currentTemplate.item;
    const { promoterTemplates, isEmailTemplatesLoading } = this.props;

    const variablesList = templateValues.map((v => (
          [v.label, 'insertText', v.value]
        )))
    
        const BtnInsert = createDropdown('Text value', variablesList);
        const BtnFontColor = createButton('Color', '', (val) => {
          document.execCommand('foreColor', false, prompt('HEX Color', '') || undefined);
          console.log(val)
        });

    const customSelectStyles = {
      control: (base) => ({
        ...base,
        height: "46px",
        "min-height": "46px",
        cursor: "pointer",
      }),
    };

    if (isEmailTemplatesLoading) {
      return (
        <div className="email">
          <NewLoader />
        </div>
      );
    }

    return (
      <div className="email">
        <div className="email__block">
          <label>Template</label>
          <Select
            options={promoterTemplates}
            name="templates"
            classNamePrefix="custom-select"
            value={currentTemplate}
            defaultValue={currentTemplate}
            onChange={(value) => this.handleChangeTemplate(value)}
            className="email__select"
            styles={customSelectStyles}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#6071B5",
                primary25: "#F2F4FE",
                primary50: "#F2F4FE",
                primary75: "#F2F4FE",
              },
            })}
          />
        </div>

        <div className="email__block">
          <label>Mail subject</label>

          <input
            type="text"
            name="subject"
            value={subject}
            minLength="3"
            maxLength="500"
            onChange={(e) => this.handleChangeTemplateField(e.target.name, e.target.value)}
            className={`email__textfield ${!isDataValid ? 'email__textfield--is-error' : ''}`}
            placeholder="Mail subject"
          />

          {!isDataValid && (
            <p className="email__error-message">
              This is a required field
            </p>
          )}
        </div>

        <div className="email__block">
          <label>Mail header</label>

          {this.state.isHeaderImageUploading ? (
            <div className="email__image-loader">
              <NewLoader /> 
            </div>
          ): (
            <Dropzone
              imgUrl={headerImageUrl}
              onFilesAdded={(files) => this.handleChangeImage(files, 'headerImageUrl')}
              onButtonEnable={() => { }}
              unsupportedFileFormatHandle={() => { }}
              onlyOneFileErrorHandle={() => { }}
            />
          )}
        </div>

        <div className="email__content-block">
          <EditorProvider>
            <Editor
              value={bodyContent}
              onChange={this.handleBodyContentChange}
            >
            <Toolbar>
              <BtnUndo />
              <BtnRedo />
              <Separator />
              <BtnBold />
              <BtnItalic />
              <BtnUnderline />
              <BtnStrikeThrough />
              <BtnFontColor />
              <Separator />
              <BtnNumberedList />
              <BtnBulletList />
              <Separator />
              <BtnLink />
              <BtnClearFormatting />
              <HtmlButton />
              <Separator />
              <BtnStyles />
              <BtnInsert />
            </Toolbar>
            </Editor>
          </EditorProvider>
        </div>

        <div className="email__buttons-container">
          <div className="email__button">
            <button
              type="button"
              className="btn-primary btn-primary--reselling"
              onClick={this.updateTemplate}
              disabled={!this.state.isEdited}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  emailTemplates: { promoterTemplates, isEmailTemplatesLoading },
}) => {
  return {
    promoterTemplates,
    isEmailTemplatesLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPromoterEmailTemplates: () => dispatch(getPromoterEmailTemplates()),
  editEmailTemplate: (templateId, template) => dispatch(editEmailTemplate(templateId, template)),
  getTemplateTextValues: () => dispatch(getTemplateTextValues()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplates);