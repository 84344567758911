import React from 'react';
import qr from '../../../../../../images/WalletQRcode.png';

import './GoogleWallet.sass';

export const GoogleWallet = ({ wallet, isQrCodeVisible }) => {
  return (
    <article className="google-wallet">
      <div className="google-wallet__header">
        {wallet.logoImageUrl && (
          <div className="google-wallet__icon-container">
            <img src={wallet.logoImageUrl} alt="logo" />
          </div>
        )}

        <div className="google-wallet__title">
          {wallet.title}
        </div>
      </div>
      <div className="google-wallet__main">
        <div className="google-wallet__event-name">
          {wallet.eventName}
        </div>
        <div className="google-wallet__seat-info">
          <div>
            <span className="google-wallet__seat-label">Gate</span>
            <p className="google-wallet__seat-data">{wallet.entranceName}</p>
          </div>
          <div>
            <span className="google-wallet__seat-label">Section</span>
            <p className="google-wallet__seat-data">{wallet.area}</p>
          </div>
          <div>
            <span className="google-wallet__seat-label">Row / Seat</span>
            <p className="google-wallet__seat-data">{wallet.rowSeat}</p>
          </div>
        </div>
        <div className="google-wallet__qr-code-container">
          {isQrCodeVisible && <img src={qr} alt="QR code" />}
        </div>
        <div className="google-wallet__ticket-name">
          {wallet.ticketName}
        </div>
      </div>
      {wallet.footerImageUrl && (
        <div className="google-wallet__footer">
          <img src={wallet.footerImageUrl} alt="footer" />
        </div>
      )}
    </article>
  )
}
