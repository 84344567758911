import React from 'react';
import qr from '../../../../../../images/WalletQRcode.png';

import './AppleWallet.sass';

export const AppleWallet = ({ wallet, isQrCodeVisible }) => {
  return (
    <article className="apple-wallet">
      <div
        className="apple-wallet__background"
        style={{ backgroundImage: `url(${wallet.backgroundImageUrl})` }}
      ></div>
      <div className="apple-wallet__title">
        {wallet.eventName}
      </div>
      <div className="apple-wallet__main">
        <div className="apple-wallet__info-general">
          <div>
            <div>
              <span className="apple-wallet__label">Ticket name</span>
              <p className="apple-wallet__data">{wallet.ticketName}</p>
            </div>

            <div className="apple-wallet__owner-gate">
              <div>
                <span className="apple-wallet__label">Owner name</span>
                <p className="apple-wallet__data">{wallet.ownerName}</p>
              </div>
              <div>
                <span className="apple-wallet__label">Gate</span>
                <p className="apple-wallet__data">{wallet.entranceName}</p>
              </div>
            </div>
          </div>
          {wallet.thumbnailImageUrl && (
            <div className="apple-wallet__picture">
              <img src={wallet.thumbnailImageUrl} alt="Thumbnail" />
            </div>
          )}
        </div>
        <div className="apple-wallet__info-seat">
          <div>
            <span className="apple-wallet__label">Area</span>
            <p className="apple-wallet__data">{wallet.area}</p>
          </div>
          <div>
            <span className="apple-wallet__label">Row</span>
            <p className="apple-wallet__data">{wallet.row}</p>
          </div>
          <div>
            <span className="apple-wallet__label">Seat</span>
            <p className="apple-wallet__data">{wallet.seat}</p>
          </div>
          <div>
            <span className="apple-wallet__label">Side</span>
            <p className="apple-wallet__data">{wallet.side}</p>
          </div>
        </div>
      </div>
      {isQrCodeVisible && (
        <div className="apple-wallet__qr">
          <img src={qr} alt="QR code" />
        </div>
      )}
    </article>
  )
}
