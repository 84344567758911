import React, { Component, createRef } from "react";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import ButtonBurger from "../UI/ButtonBurger/ButtonBurger";
import ROUTES from "../../constants/routes";
import Icon from "../UI/Icon/Icon";
import { logout } from "../../store/actions/authActions";
import ROLES from "../../constants/roles";
import Swal from "sweetalert2";
import ls from 'localstorage-slim';

import "./Sidebar.sass";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
class Sidebar extends Component {
  state = {
    isSidebarState: true,
  };
  modalRef = createRef(null);

  toggleSidebar = (isSidebarState) => this.setState({ isSidebarState });
  logoutEvent = () => {
    MySwal.fire({
      title: "Are you sure you want to log out?",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      showConfirmButton: true,
      confirmButtonText: "Log out",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.props.logout();
        this.props.history.push(ROUTES.LOGIN);
      }
    });
  };

  isLinkActive = (match, location, typeOfRoute) => {
    const routeNormalizer = (route) => route.split("/")[1];

    switch (typeOfRoute) {
      case ROUTES.EVENTS:
        return (
          match ||
          routeNormalizer(location.pathname) === routeNormalizer(ROUTES.NEW_EVENT) ||
          location.pathname === ROUTES.NEW_ADD_EVENT ||
          routeNormalizer(location.pathname) ===
          routeNormalizer(ROUTES.ADD_TICKET)
        );
      case ROUTES.SCANTEAMS:
        return (
          match ||
          routeNormalizer(location.pathname) ===
          routeNormalizer(ROUTES.SCANTEAMS_VIEW)
        );
      default:
        return (
          routeNormalizer(location.pathname).includes(
            routeNormalizer(typeOfRoute)
          )
        )
    }
  };

  componentDidUpdate() {
    if (!this.state.isSidebarState) {
      disablePageScroll(this.modalRef.current);
    } else {
      enablePageScroll(this.modalRef.current);
    }
  }

  render() {
    const { isSidebarState } = this.state;
    const {
      auth: {
        user: { role },
      },
    } = this.props;
    const userCopy = JSON.parse(localStorage.getItem("userCopy"));
    const user = ls.get("user");

    let navigation = null;

    if (
      role === ROLES.PROMOTER &&
      window.location.pathname !== ROUTES.PROMOTERS
    ) {
      navigation = (
        <>
          <NavLink
            className="sidebar-item events"
            to={ROUTES.EVENTS}
            onClick={this.toggleSidebar}
            isActive={(match, location) =>
              this.isLinkActive(match, location, ROUTES.EVENTS)
            }
          >
            <Icon name="events" />
            <span>Events</span>
          </NavLink>
          <NavLink
            className="sidebar-item groups"
            to={ROUTES.SCANTEAMS}
            onClick={this.toggleSidebar}
            isActive={(match, location) =>
              this.isLinkActive(match, location, ROUTES.SCANTEAMS)
            }
          >
            <Icon name="groups" />
            <span>Teams</span>
          </NavLink>
          {/* <NavLink
            className="sidebar-item payment"
            to={ROUTES.PAYMENTS}
            onClick={this.toggleSidebar}
            isActive={(match, location) =>
              this.isLinkActive(match, location, ROUTES.PAYMENTS)
            }
          >
            <Icon name="payment" />
            <span>Payment</span>
          </NavLink> */}
          {/* <NavLink
            className="sidebar-item payment"
            to={ROUTES.DASHBOARD}
            onClick={this.toggleSidebar}
          >
            <Icon name="dashboard-stats" />
            <span>Dashboard</span>
          </NavLink> */}
          {/* <NavLink
            className="sidebar-item payment"
            to={ROUTES.STATISTICS}
            onClick={this.toggleSidebar}
            isActive={(match, location) =>
              this.isLinkActive(match, location, ROUTES.STATISTICS)
            }
          >
            <Icon name="dashboard-stats" />
            <span>Statistics</span>
          </NavLink> */}
          <NavLink
            className="sidebar-item group-people"
            to={ROUTES.MEMBERSHIP_MANAGMENT}
            isActive={(match, location) =>
              this.isLinkActive(match, location, ROUTES.MEMBERSHIP_MANAGMENT)
            }
            onClick={this.toggleSidebar}
          >
            <Icon name="group-people" />
            <span>Membership</span>
          </NavLink>

          <NavLink
            className="sidebar-item emails"
            to={ROUTES.EMAILS}
            isActive={(match, location) =>
              this.isLinkActive(match, location, ROUTES.EMAILS)
            }
            onClick={this.toggleSidebar}
          >
            <Icon name="emails" />
            <span>Emails</span>
          </NavLink>

          <NavLink
            className="sidebar-item promoter"
            to={ROUTES.PROMOTER}
            isActive={(match, location) =>
              this.isLinkActive(match, location, ROUTES.PROMOTER)
            }
            onClick={this.toggleSidebar}
          >
            <Icon name="promoter" />
            <span>Promoter</span>
          </NavLink>

          {/* <NavLink className="sidebar-item faq" to={ROUTES.FAQ} onClick={this.toggleSidebar}>
                        <Icon name="faq"/>
                        <span>FAQ</span>
                    </NavLink> */}
        </>
      );
    } else if (
      role === ROLES.ADMIN ||
      (userCopy && userCopy.role === ROLES.ADMIN)
    ) {
      navigation = (
        <NavLink
          className="sidebar-item groups"
          to={ROUTES.PROMOTERS}
          onClick={this.toggleSidebar}
        >
          <Icon name="groups" />
          <span>Promoters</span>
        </NavLink>
      );
    }

    return (
      <div
        className={isSidebarState ? "sidebar minimized" : "sidebar"}
        ref={this.modalRef}
      >
        <ButtonBurger toggleSidebar={this.toggleSidebar} />
        <div className="sidebar__items-wrapper">
          {navigation}
          {/*TODO: need for future development*/}
          {/*<NavLink className="sidebar-item settings" to="/s"  onClick={this.toggleSidebar}>*/}
          {/*<Icon name="settings"/>*/}
          {/*<span>Settings</span>*/}
          {/*</NavLink>*/}
          <div className="sidebar-item logout" onClick={this.logoutEvent}>
            <Icon name="logout" />
            <span>Log out</span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({ auth });

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logout());
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
