import React from "react";

import "./InputValidation.sass";

export const InputValidation = ({
  value,
  className,
  placeholder = "Enter text",
  isValid = true,
  errorMessage = "",
  onChange = (e) => {},
  onBlur = () => {},
  disabled = false
}) => {
  const errorClass = isValid ? " hide-error" : "";
  const inputClassName = `input-validation ${className}` + errorClass;
  const errorClassName = "input-validation-error" + errorClass;

  return (
    <>
      <input
        value={value}
        placeholder={placeholder}
        className={inputClassName}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
      <span className={errorClassName}>{errorMessage}</span>
    </>
  );
};
