import { showInfoModal } from "./showInfoModal";
import SomethingWentWrong from "../images/something-went-wrong.svg";
import { getCurrentLanguageTexts } from "../constants/seasonPassPersonalInfoLangs";

export const showErrorModal = ({ ...options }) => {
  const txt = getCurrentLanguageTexts();

  showInfoModal({
    image: SomethingWentWrong,
    title: txt.generalTxt.somethingWentWrong,
    imageAltText: txt.generalTxt.somethingWentWrong,
    showConfirmButton: true,
    confirmButtonText: txt.generalTxt.tryAgain,
    ...options,
  });
}