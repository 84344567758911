import React, { Component } from 'react';

import '../BarCodes.sass'

class ImportantModal extends Component {
    render() {
        const { all, updated, duplicate, errorSeatsCount, errorSeats } = this.props;
        return (
            <div>
                <strong>It's important!</strong>
                Check any ticket from this list before the event!
                <div className="success-content">
                    <p>Success!</p>
                    <div className="results">
                        <p>All tickets in file: <span>{all}</span></p>
                        <p>Dublicated tickets: <span>{duplicate}</span></p>
                        <p>Updated tickets: <span>{updated}</span></p>
                        <p>Tickets with wrong seats: <span>{errorSeatsCount}</span></p>
                        {errorSeats.length > 0 && <p>Wrong seats:</p>}
                        {errorSeats.map((seat) => (
                          <p>{seat}</p>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default ImportantModal;
