import React, { Component } from "react";
import ROUTES from "../../constants/routes";
import { NavLink, withRouter } from "react-router-dom";
import ls from 'localstorage-slim';

import "./NavigationTabs.sass";

class NavigationTabs extends Component {
  render() {
    const user = ls.get("user");

    const links = [
      {
        title: 'Info',
        route: ROUTES.PROMOTER_INFO,
      },
      {
        title: 'Templates',
        route: ROUTES.PROMOTER_TEMPLATES,
      },
    ];

    if (user.hasPayNlAccount) {
      links.push({
        title: 'Balance',
        route: ROUTES.PROMOTER_BALANCE,
      })
    }

    return (
      <nav className="nav-tabs">
        <div className="nav-tabs__container nav-tabs__container--small">
          {links.map(link => {
            return (
              <NavLink
                className="nav-tabs__item"
                to={link.route}
                key={link.route}
              >
                <span>{link.title}</span>
              </NavLink>
            )
          })}
        </div>
      </nav>
    ); 
  }
};

export default withRouter(NavigationTabs);

