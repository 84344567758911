import React from "react";
import { useShowActionsMenu } from "../../components/UI/ActionsMenu/hooks/useShowActionsMenu";
import { IconButton } from "../../components/UI/IconButton/IconButton";


export const EmailAction = ({
  email,
  resendEmail
}) => {
  const [showMenuActions] = useShowActionsMenu();

  const menuItems = [];

  menuItems.push({
    text: "Resend",
    onClick: resendEmail,
    data: email,
  });

  const optionsClassName =
    menuItems.length > 0
      ? "btn-primary IconButton primary trigger-actions-menu"
      : "IconButton gray trigger-actions-menu";

  const optionsIconName = menuItems.length > 0 ? "options" : "options-gray";

  const menuOptions = {
    items: menuItems,
    minMenuHeight: 35 * menuItems.length,
    offsetY: 7,
  };

  return (
    <div className="buttons-block">
      <IconButton
        iconName={optionsIconName}
        className={optionsClassName}
        onClick={showMenuActions(menuOptions)}
      />
    </div>
  );
};
