import React, { Component } from "react";

import "../BarCodes.sass";

class PleaseWaitModal extends Component {
  render() {
    const { message } = this.props;
    return (
      <div>
        <strong>Please wait</strong>
        <p>{message}</p>
      </div>
    );
  }
}

export default PleaseWaitModal;
