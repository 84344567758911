import React, { useState } from "react";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";
import NewLoader from "../../../components/NewLoader/NewLoader";
import axios from "../../../axios-instance";
import { getHeaders } from "../../../helpers/getHeaders";

import "./ChangePointsModal.sass";

export const ChangePointsModal = ({ id, token, getData }) => {
  const [enteredPoints, setEnteredPoints] = useState("");
  const [operation, setOperation] = useState("add");
  const [loading, setLoading] = useState(false);

  const MySwal = withReactContent(swal);

  const handleChangePointsInput = (e) => {
    if (e.target.value.length > 6) {
      return;
    }

    e.target.value = e.target.value.replace(/[^\d.]/g, '');
    
    setEnteredPoints(e.target.value);
  }

  const handleChangeOption = (e) => {
    setOperation(e.target.value);
  }

  const requestChangePoints = async () => {
    if (enteredPoints === "") return;

    let points = +enteredPoints;

    if (operation === "remove") {
      points = -points;
    } else {
      points = Math.abs(points)
    }

    try {
      setLoading(true)
      await axios.post(
        `/MembershipManagment/${id}/AddPoints?points=${points}`,
        null,
        { headers: getHeaders(token) }
      );

      toast.success("Points changed successfully")

      MySwal.close();
      getData();
    } catch (error) {
      toast.error("Something went wrong")
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return (
      <div className="points-modal-loader">
        <NewLoader />
      </div>
    )
  }

  return (
    <div className="points-modal">
      <h4 className="points-modal__title">Change points</h4>
      <div className="points-modal__radio-wrapper">
        <label className="points-modal__radio">
          <input
            className="points-modal__input"
            name="points"
            type="radio"
            value="add"
            onChange={handleChangeOption}
            checked={operation === "add"}
          />
          <span>Add points</span>
        </label>
        <label className="points-modal__radio">
        <input
            className="points-modal__input"
            name="points"
            type="radio"
            value="remove"
            onChange={handleChangeOption}
            checked={operation === "remove"}
          />
          <span>Remove points</span>
        </label>
      </div>

      <input
        className="points-modal__points-input input-behavior"
        type="text"
        placeholder="Enter points"
        value={enteredPoints}
        onChange={handleChangePointsInput}
        autoFocus
      />

      <div className="points-modal__buttons">
      <button
        type='button'
        className='points-modal__button btn-secondary'
        onClick={() => MySwal.close()}
      >
        <span>Cancel</span>
      </button>
      <button
        type='button'
        className='points-modal__button btn-primary'
        onClick={requestChangePoints}
      >
        <span>Change</span>
      </button>
      </div>
    </div>
  )
}
