import React, { useEffect, useState } from "react";
import ls from 'localstorage-slim';
import Container from "../../components/UI/Container/Container";
import PageTitle from "../../components/UI/PageTitle/PageTitle";
import NewLoader from "../../components/NewLoader/NewLoader";
import axios from '../../axios-instance';
import { getHeaders } from "../../helpers/getHeaders";
import { currencies, getCurrencieLabel, getCurrencieObject } from "../../constants/currencies";
import ROUTES from "../../constants/routes";
import Select from "react-select";

import styles from './ProductDefinitions.module.sass';
import '../../style/admin/admin.sass';
import Icon from "../../components/UI/Icon/Icon";
import { price } from "../SeasonPass/constants";

export const ProductDefinitionDetail = () => {
    const [state, setStateInternal] = useState({
        loading: true,
        id: window.location.pathname.split('products/')[1],
        product: {}
    });

    const setState = (changes) => setStateInternal({ ...state, ...changes });
    const changeProductField = (changes) => setStateInternal({
        ...state, product: {
            ...state.product,
            ...changes
        }
    });

    const changeField = (event, fieldName) => {
        const value = event.target.value;
        changeProductField({
            [fieldName]: value,
        })
    }

    const changeCurrency = (currencyId) => {
        changeProductField({
            currencyId,
        })
    }

    const user = ls.get('user');

    useEffect(() => {
        axios.get(`/ProductDefinition/${state.id}`, {
            headers: getHeaders(user.token),
        }).then((r) => {
            setState({
                product: r.data,
                loading: false
            });
        })
    }, []);

    const saveHandler = (e) => {
        axios.put(`/ProductDefinition/${state.id}`,
            {
                ...state.product,
                fixedFee: !state.product.fixedFee ? 0 : state.product.fixedFee,
                feeRate: !state.product.feeRate ? 0 : state.product.feeRate,
                price: !state.product.price ? 0 : state.product.price,
            },
            {
                headers: getHeaders(user.token),
            }).then((r) => {
                setState({
                    product: r.data,
                    loading: false
                });
            })
    };

    const product = state.product;

    return <Container className='admin-page'>
        <PageTitle>Products</PageTitle>

        {state.loading
            ? <NewLoader />
            : (
                <section className='admin-container'>
                    <div className='admin-container__row'>
                        <div className='admin-container__block'>
                            <label>Product name</label>
                            <input
                                placeholder='Enter product name'
                                type='text'
                                minLength='3'
                                maxLength='120'
                                value={product.name}
                                onChange={(e) => changeField(e, 'name')}
                            />
                        </div>
                    </div>

                    <div className='admin-container__row'>
                        <div className='admin-container__block'>
                            <label>Price</label>
                            <input
                                placeholder='Enter product price'
                                type='number'
                                minLength='1'
                                maxLength='120'
                                value={product.price}
                                onChange={(e) => changeField(e, 'price')}
                            />
                        </div>

                        <div className="admin-container__block">
                            <label>Currency</label>

                            <Select
                                options={currencies}
                                name="currency"
                                classNamePrefix="custom-select"
                                value={getCurrencieObject(product.currencyId)}
                                defaultValue={getCurrencieObject(product.currencyId)}
                                onChange={(value) => changeCurrency(value.value)}
                                className="custom-select"
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: "#6071B5",
                                        primary25: "#F2F4FE",
                                        primary50: "#F2F4FE",
                                        primary75: "#F2F4FE",
                                    },
                                })}
                            />
                        </div>
                    </div>

                    <div className='admin-container__row'>
                        <div className='admin-container__block'>
                            <label>Fee rate</label>
                            <input
                                placeholder='Enter product fee rate'
                                type='number'
                                minLength='1'
                                maxLength='120'
                                value={product.feeRate}
                                onChange={(e) => changeField(e, 'feeRate')}
                            />
                        </div>

                        <div className='admin-container__block'>
                            <label>Fixed fee</label>
                            <input
                                placeholder='Enter fixed fee'
                                type='number'
                                minLength='1'
                                maxLength='120'
                                value={product.fixedFee}
                                onChange={(e) => changeField(e, 'fixedFee')}
                            />
                        </div>
                    </div>

                    <div className='page-footer'>

                        <button
                            type='button'
                            className='btn-primary'
                            onClick={saveHandler}>

                            <Icon name='save' />
                            <span>Save</span>

                        </button>
                    </div>

                </section>
            )
        }
    </Container>
}