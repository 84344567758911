import React, { Component } from "react";
import { connect } from "react-redux";

import './PromoterInfo.sass';

class PromoterInfo extends Component {
  render() {
    const { user } = this.props;

    return (
      <div className="promoter-info info">
        <div className="add-event__row">
          <div className="add-event__column">
            <div className="add-event__block">
              <label>Name</label>
              <span className="add-event__block__count">
                {user.name}
              </span>
            </div>
          </div>

          <div className="add-event__column">
            <div className="add-event__block">
              <label>Email</label>
              <span className="add-event__block__count">
                {user.email}
              </span>
            </div>
          </div>

          <div className="add-event__column">
            <div className="add-event__block">
              <label>Address</label>
              <span className="add-event__block__count">
                {user.address}
              </span>
            </div>
          </div>
        </div>

        <div className="add-event__row">
          <div className="add-event__column">
            <div className="add-event__block">
              <label>Company name</label>
              <span className="add-event__block__count">
                {user.companyName}
              </span>
            </div>
          </div>

          <div className="add-event__column">
            <div className="add-event__block">
              <label>Website</label>
              <span className="add-event__block__count">
                {user.website}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  auth: { user },
}) => ({ user });

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PromoterInfo);