import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import queryString from 'query-string';

import { connect } from 'react-redux';
import {
  clearCurrentEvent,
  getEvent,
  resetEventsError,
} from '../../../store/actions/eventsActions';

import PageTitle from '../../../components/UI/PageTitle/PageTitle';
import NewLoader from '../../../components/NewLoader/NewLoader';
import NavigationTabs from '../../../components/NavigationTabs/EventNavigationTabs.jsx';
import ROUTES from '../../../constants/routes';
import Page404 from '../../../components/Page404/Page404.js';

import './NewEvent.sass';

import AddEvent from '../NewAddEvent/AddEvent.js';

import EventInfo from './NewEventPages/EventInfo/EventInfo';
import EventReselling from './NewEventPages/EventReselling';
import EventTickets from './NewEventPages/EventTickets/EventTickets';
import EventReports from './NewEventPages/EventReports/EventReports';
import EventStatistics from './NewEventPages/EventStatistics/EventStatistics';
import EventEmailEditor from './NewEventPages/EventEmail/EventEmail';
import EventPdfEditor from './NewEventPages/EventPDF/EventPDF';
import EventSell from './NewEventPages/EventSell/EventSell';
import EventSeats from './NewEventPages/EventSeats/EventSeats.js';
import EventTransactions from './NewEventPages/EventTransactions/EventTransactions';
import EventManagment from './NewEventPages/EventManagement/Management.js';
import EventWallets from './NewEventPages/EventWallets/EventWallets';

import TicketDetail from './NewTicketsPages/TicketDetail/TicketDetail.js';
import BarCodes from './NewTicketsPages/BarCodes/BarCodes.js';
import GeneratePdfs from './NewTicketsPages/TicketActions/GeneratePdfs';
import SendBuyEmail from './NewTicketsPages/TicketActions/SendBuyEmail';

class NewEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentEvent: {},
      isFound: true,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.currentEvent.id &&
      props.currentEvent.id !== state.currentEvent.id
    ) {
      const currentEvent = {
        ...props.currentEvent,
      };
      return {
        currentEvent,
      };
    }
    return null;
  }

  componentDidMount() {
    const { path } = this.props.match;
    const action = queryString.parse(this.props.location.search).action;

    if (path !== ROUTES.NEW_ADD_EVENT) {
      this.props
        .getEvent(this.props.match.params.id, this.props.user.token, action)
        .then((result) => {
          if (!result.isFound) {
            this.setState({ isFound: false });
          }
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.error && this.props.error !== prevProps.error) {
      toast.error(String(this.props.error));

      this.props.resetEventsError();
    }
  }

  componentWillUnmount() {
    this.props.clearCurrentEvent();
  }

  render() {
    const { name } = this.props.currentEvent;
    const currPage = this.props.location.pathname.split('/').at(-1);
    const {
      match: { params },
    } = this.props;
    const isTicketTitle = params.ticket_id && params.ticket_id !== 'add';
    const {
      currentTicket: { ticketName },
      loading,
    } = this.props;

    if (!this.state.isFound) return <Page404 />;

    return (
      <section className='new-event'>
        <div className='new-event__wrapper'>
          <div className='new-event__nav'>
            <NavigationTabs />
          </div>

          <div className='new-event__container'>
            {this.props.loading &&
              currPage !== ROUTES.NEW_EVENT_STATISTICS.split('/').at(-1) && (
                <div className='new-event__loader'>
                  <NewLoader />
                </div>
              )}
            <div className='new-event__title'>
              <PageTitle>
                {name || 'New event'}
                {isTicketTitle && ` / ${ticketName}`}
              </PageTitle>
            </div>

            <Switch>
              <Redirect
                from={ROUTES.NEW_EVENT}
                to={ROUTES.NEW_EVENT_INFO}
                exact
              />

              <Route path={ROUTES.NEW_ADD_EVENT} exact component={AddEvent} />

              <Route path={ROUTES.NEW_EVENT_INFO} exact component={EventInfo} />
              <Route
                path={ROUTES.NEW_EVENT_TICKETS}
                exact
                component={EventTickets}
              />
              <Route
                path={ROUTES.NEW_EVENT_PDF}
                exact
                component={EventPdfEditor}
              />
              <Route
                path={ROUTES.NEW_EVENT_EMAIL}
                exact
                component={EventEmailEditor}
              />
              <Route
                path={ROUTES.NEW_EVENT_TRANSACTIONS}
                exact
                component={EventTransactions}
              />
              <Route path={ROUTES.NEW_EVENT_SELL} exact component={EventSell} />
              <Route
                path={ROUTES.NEW_EVENT_SEATS}
                exact
                component={EventSeats}
              />
              <Route
                path={ROUTES.NEW_EVENT_REPORTS}
                exact
                component={EventReports}
              />
              <Route
                path={ROUTES.NEW_EVENT_STATISTICS}
                exact
                component={EventStatistics}
              />
              <Route
                path={ROUTES.NEW_EVENT_RESELLING}
                exact
                component={EventReselling}
              />
              <Route
                path={ROUTES.NEW_EVENT_MANAGEMENT}
                exact
                component={EventManagment}
              />
              <Route
                path={ROUTES.NEW_EVENT_WALLETS}
                exact
                component={EventWallets}
              />

              <Route
                path={ROUTES.NEW_TICKET_DETAIL}
                exact
                component={TicketDetail}
              />
              <Route
                path={ROUTES.NEW_ADD_TICKET}
                exact
                component={TicketDetail}
              />
              <Route
                path={ROUTES.NEW_ADD_BAR_CODE}
                exact
                component={BarCodes}
              />
              <Route
                path={ROUTES.NEW_GENERATE_PDFS}
                exact
                component={GeneratePdfs}
              />
              <Route
                path={ROUTES.NEW_SEND_BUY_EMAIL}
                exact
                component={SendBuyEmail}
              />
            </Switch>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({
  auth: { user },
  events: { currentEvent, loading, error, currentTicket },
}) => ({ user, currentEvent, loading, error, currentTicket });

const mapDispatchToProps = (dispatch) => ({
  getEvent: (eventId, token, action) =>
    dispatch(getEvent(eventId, token, action)),
  clearCurrentEvent: () => dispatch(clearCurrentEvent()),
  resetEventsError: () => dispatch(resetEventsError()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewEvent));
