import React, { Component } from "react";
import ROUTES from "../../constants/routes";
import { NavLink, withRouter } from "react-router-dom";

import "./NavigationTabs.sass";

class NavigationTabs extends Component {
  state = {
    isAddEvent: this.props.match.path === ROUTES.NEW_ADD_EVENT
  };

  goToPage = (route) => {
    return route.replace(":id", this.props.match.params.id);
  };

  isPageActive = (match, location, title) => {
    const isTicket = location.pathname.includes('/ticket/');
    const isSell = location.pathname.includes('/sell/');

    return match ||
      (isTicket && title === "Tickets") ||
      (isSell && title === "Sell") ||
      (this.state.isAddEvent && title === "Info");
  };

  static getDerivedStateFromProps(props) {
    if (props.match.params.id) {
      return {
        isAddEvent: false,
      };
    }
    return null;
  }

  render() {
    const links = [
      {
        title: 'Info',
        route: this.state.isAddEvent ?
          ROUTES.NEW_ADD_EVENT :
          ROUTES.NEW_EVENT_INFO,
      },
      {
        title: 'PDF',
        route: ROUTES.NEW_EVENT_PDF,
      },
      {
        title: 'Tickets',
        route: ROUTES.NEW_EVENT_TICKETS,
      },
      {
        title: 'Email',
        route: ROUTES.NEW_EVENT_EMAIL,
      },
      {
        title: 'Wallets',
        route: ROUTES.NEW_EVENT_WALLETS,
      },
      {
        title: 'Transactions',
        route: ROUTES.NEW_EVENT_TRANSACTIONS,
      },
      {
        title: 'Sell',
        route: ROUTES.NEW_EVENT_SELL.split("/").slice(0, -1).join("/"),
      },
      {
        title: 'Seats',
        route: ROUTES.NEW_EVENT_SEATS,
      },
      {
        title: 'Reports',
        route: ROUTES.NEW_EVENT_REPORTS,
      },
      {
        title: 'Statistics',
        route: ROUTES.NEW_EVENT_STATISTICS,
      },
      {
        title: 'Management',
        route: ROUTES.NEW_EVENT_MANAGEMENT,
      },
      {
        title: 'Reselling',
        route: ROUTES.NEW_EVENT_RESELLING,
      },
    ];

    return (
      <nav className="nav-tabs">
        <div className="nav-tabs__container">
          <NavLink
            className="nav-tabs__item"
            to={this.goToPage(links[0].route)}
            key={links[0].route}
            isActive={(match, location) =>
              this.isPageActive(match, location, links[0].title)
            }
          >
            <span>{links[0].title}</span>
          </NavLink>
          {links.slice(1).map(link => {
            if (!this.state.isAddEvent) {
              return (
                <NavLink
                  className="nav-tabs__item"
                  to={this.goToPage(link.route)}
                  key={link.route}
                  isActive={(match, location) =>
                    this.isPageActive(match, location, link.title)
                  }
                >
                  <span>{link.title}</span>
                </NavLink>
              )
            } else {
              return (
                <div
                  className="nav-tabs__item disabled"
                  key={link.route}
                >
                  <span>{link.title}</span>
                </div>
              )
            }
          })}
        </div>
      </nav>
    ); 
  }
};

export default withRouter(NavigationTabs);

