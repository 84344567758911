import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import queryString from "query-string";

import FirstTab from "../../../../../components/BarCodes/FirstTab/FirstTab";
import SecondTab from "../../../../../components/BarCodes/SecondTab/SecondTab";
import ThirdTab from "../../../../../components/BarCodes/ThirdTab/ThirdTab";
import FourthTab from "../../../../../components/BarCodes/FourthTab/FourthTab";
import Trigger from "../../../../../components/UI/Collapsible/Trigger/Trigger";
import Icon from "../../../../../components/UI/Icon/Icon";
import PleaseWaitModal from "./modals/PleaseWaitModal";
import ImportantModal from "./modals/ImportantModal";
import ErrorModal from "./modals/ErrorModal";
import {
  getProviders,
  uploadBarcodes,
  uploadTicketsTable,
  getProviderConfig,
} from "../../../../../store/actions/barCodesActions";
import ROUTES from "../../../../../constants/routes";
import Page404 from "../../../../../components/Page404/Page404";

import "./BarCodes.sass";
import {
  clearCurrentEvent,
  getEvent,
  getTicket,
} from "../../../../../store/actions/eventsActions";

const MySwal = withReactContent(Swal);

class BarCodes extends Component {
  state = {
    currentTab: 1,
    prevTab: 5,
    showTabContent: false,
    buttonName: "Next",
    buttonDisable: true,
    searchProviderValue: "default",
    selectedProvider: "",
    ticketTable: null,
    ticketTableContent: null,
    inputValue: "RIO Club",
    mainColumnData: [],
    searchValue: "default",
    isFound: true,
    isLineIgnore: false,
    needBookSeats: true,
  };

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.getProviders(user.token);

    const {
      user: { token },
      match: { params },
      getEvent,
      getTicket,
    } = this.props;

    if (params.ticket_id) {
      getTicket(params.ticket_id, token).then((result) => {
        if (!result.isFound) {
          this.setState({ isFound: false });
        }
      });
    }

    const action = queryString.parse(this.props.location.search).action;

    if (!this.props.currentEvent.id)
      getEvent(params.id, token, action).then((result) => {
        if (!result.isFound) {
          this.setState({ isFound: false });
        }
      });
  }

  getProviders = (userToken) => {
    const { searchProviderValue } = this.state;
    const { getProviders } = this.props;

    getProviders(userToken, searchProviderValue, 0, 0);
  };

  handleChange = (type, value) => {
    if (type === "isLineIgnore" || type === "needBookSeats") {
      this.setState({
        [type]: value,
      });
    } else {
      this.setState({
        [type]: value,
        buttonDisable: false,
      });
    }
  };

  handleSelectSearchValue = (value, meta) => {
    if (value.length > 255) {
      return;
    }

    if (meta.action !== "input-blur" && meta.action !== "menu-close") {
      let { buttonDisable, selectedProvider } = this.state;

      if (!selectedProvider) {
        buttonDisable = !value.length;
      }

      this.setState({
        searchValue: value,
        buttonDisable,
        selectedProvider: "",
      });
    }
  };

  clickNextHandler = () => {

    this.setState({
      currentTab: this.state.currentTab === 1 ? 3 : this.state.currentTab + 1,
      prevTab: this.state.prevTab - 1,
      showTabContent: true,
    });
    if (this.state.currentTab === 1) {
      this.uploadTicketTable();
    }

    if (this.state.currentTab === 1) {
      this.setState({ buttonDisable: true });
    }

    if (this.state.currentTab === 2) {
      this.state.selectedProvider && this.getProviderConfig();
      this.setState({ buttonDisable: true, currentTab: 3 });
    }

    if (this.state.currentTab === 4) {
      MySwal.fire({
        html: <PleaseWaitModal message="Your tickets are uploading" />,
        customClass: "please-wait-modal",
      });

      const {
        ticketTableContent,
        checkedFieldAccessors,
        selectedProvider,
        searchValue,
        isLineIgnore,
      } = this.state;

      const fieldsIndexes = Object.keys(checkedFieldAccessors).reduce(
        (accumulator, key) => {
          switch (checkedFieldAccessors[key]) {
            case "barCode":
              accumulator["barcodeIndex"] = key.slice(3);
              break;
            case "phone":
              accumulator["phoneIndex"] = key.slice(3);
              break;
            case "email":
              accumulator["emailIndex"] = key.slice(3);
              break;
            case "name":
              accumulator["nameIndex"] = key.slice(3);
              break;
            case "gender":
              accumulator["genderIndex"] = key.slice(3);
              break;
            case "age":
              accumulator["ageIndex"] = key.slice(3);
              break;
            case "address":
              accumulator["addressIndex"] = key.slice(3);
              break;
            case "area":
              accumulator["areaIndex"] = key.slice(3);
              break;
            case "side":
              accumulator["sideIndex"] = key.slice(3);
              break;
            case "row":
              accumulator["rowIndex"] = key.slice(3);
              break;
            case "seat":
              accumulator["seatIndex"] = key.slice(3);
              break;
            default:
              break;
          }
          return accumulator;
        },
        {}
      );

      const dataToSend = {
        ticketTemplateId: this.props.match.params.ticket_id,
        sessionId: ticketTableContent.sessionId,
        providerName: selectedProvider ? selectedProvider.label : searchValue,
        isHeaderIncluded: isLineIgnore,
        barcodeIndex: null,
        phoneIndex: null,
        emailIndex: null,
        nameIndex: null,
        genderIndex: null,
        ageIndex: null,
        addressIndex: null,
        ...fieldsIndexes,
      };

      this.props
        .uploadBarcodes(dataToSend, this.props.user.token, this.state.needBookSeats)
        .then((result) => {
          MySwal.fire({
            html: (
              <ImportantModal
                all={result.allTicketsCount}
                updated={result.updatedTicketsCount}
                duplicate={result.dublicatedTicketsCount}
                errorSeatsCount={result.errorSeatsCount}
                errorSeats={result.errorSeats}
              />
            ),
            customClass: "important-modal",
            showCancelButton: false,
            showConfirmButton: true,
          }).then(() => {
            this.props.history.push(
              ROUTES.NEW_EVENT_TICKETS.replace(":id", this.props.match.params.id)
            );
          });
        })
        .catch(() => {
          MySwal.fire({
            html: <ErrorModal />,
            customClass: "error-modal",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Try again",
          });
        });
    } else if (this.state.currentTab === 3) {
      this.setState({
        buttonName: "Confirm",
      });
    }
  };

  getProviderConfig = () => {
    this.props
      .getProviderConfig(
        this.props.user.token,
        this.state.selectedProvider.value
      )
      .then((data) => {
        if (data && data.barcodeIndex !== null) {
          this.setState({ buttonDisable: false });
        }
      });
  };

  onButtonEnable = (file) => {
    if (file.length !== 0 || file["length"] !== 0) {
      this.setState({
        buttonDisable: false,
        ticketTable: file,
      });
    } else {
      this.setState({
        buttonDisable: true,
      });
    }
  };

  uploadTicketTable = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { ticketTable } = this.state;
    const { uploadTicketsTable } = this.props;

    uploadTicketsTable(user.token, ticketTable).then((data) => {
      if (!data.message && !data.isSuccess) {
        this.setState({ currentTab: 1, prevTab: 5 });
        Swal({
          title: "Error!",
          text: data.errorMessage,
          type: "error",
        }).then(() => {
          if (Number(data.code) === 4304 || Number(data.code) === 4302)
            this.props.history.push(ROUTES.PAYMENTS);
        });
      } else {
        this.setState({ ticketTableContent: data.result });
      }
    });
  };

  updateMainColumnData = (mainColumnData, checkedFieldAccessors) => {
    let buttonDisable = mainColumnData[0]
      ? !mainColumnData[0].hasOwnProperty("barCode")
      : true;
    this.setState({ mainColumnData, buttonDisable, checkedFieldAccessors });
  };

  render() {
    if (!this.state.isFound) {
      return <Page404 />;
    }

    const { providers } = this.props.barCodes;

    const { searchValue, selectedProvider, isLineIgnore, needBookSeats } = this.state;

    const Stepper = (props) => <div className="Stepper">{props.children}</div>;

    const Step = (props) => (
      <div className="Stepper__step">
        <div className="Stepper__title">
          <div className="Stepper__indicator">
            <span className="Stepper__info">
              {props.indicator}
              <Icon name="tick" className="icon" />
            </span>
          </div>
          <div className="Stepper__label">{props.title}</div>
        </div>

        <div className="Stepper__panel">{props.children}</div>
      </div>
    );

    const helpText = (
      <div>
        <p>If you have some problems, watch a short video-guide.</p>
        <div className="video-wrapper"></div>
      </div>
    );

    const trigger = <Trigger title="Help" />;

    const { currentEvent, currentTicket } = this.props;

    return (
      <div className="ticket-bar-codes">
        <Stepper>
          <div
            className={`start-tab ${this.state.prevTab <= 4 ? "prev-tab" : ""}`}
          >
            <Step indicator="1" title="Upload tickets"></Step>
          </div>

          <div
            className={
              `${this.state.currentTab === 2 ? "current-tab" : ""}` +
              `${this.state.prevTab <= 3 ? " prev-tab" : ""}`
            }
          >
            <Step indicator="2" title="Choose provider" />
          </div>

          <div
            className={
              `${this.state.currentTab === 3 ? "current-tab" : ""}` +
              `${this.state.prevTab <= 2 ? " prev-tab" : ""}`
            }
          >
            <Step indicator="3" title="Edit table"></Step>
          </div>

          <div
            className={
              `${this.state.currentTab === 4 ? "current-tab" : ""}` +
              `${this.state.prevTab <= 1 ? " prev-tab" : ""}`
            }
          >
            <Step indicator="4" title="Confirm"></Step>
          </div>
        </Stepper>
        <div className={`content ${this.state.currentTab === 1 ? "show" : ""}`}>
          <FirstTab onButtonEnable={this.onButtonEnable} />
        </div>
        <div className={`content ${this.state.currentTab === 2 ? "show" : ""}`}>
          <SecondTab
            providers={providers}
            handleChange={this.handleChange}
            searchInputHandle={this.handleSelectSearchValue}
            searchValue={searchValue}
          />
        </div>
        <div className={`content ${this.state.currentTab === 3 ? "show" : ""}`}>
          <ThirdTab
            tableContent={this.state.ticketTableContent}
            updateMainColumnData={this.updateMainColumnData}
            isLoading={this.props.barCodes.loading}
            tableConfig={this.props.barCodes.providerConfig}
            handleChange={this.handleChange}
            isLineIgnore={isLineIgnore}
            needBookSeats={needBookSeats}
          />
        </div>
        <div className={`content ${this.state.currentTab >= 4 ? "show" : ""}`}>
          <FourthTab
            inputValue={selectedProvider ? selectedProvider.label : searchValue}
            tableData={this.state.mainColumnData}
            isLineIgnore={isLineIgnore}
          />
        </div>
        <div className="button">
          <button
            onClick={this.clickNextHandler}
            className="btn-primary"
            disabled={this.state.buttonDisable}
          >
            {this.state.buttonName}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth: { user },
  events: { currentEvent, currentTicket },
  barCodes,
  barCodesTable,
}) => ({ user, currentEvent, currentTicket, barCodes, barCodesTable });

const mapDispatchToProps = (dispatch) => ({
  getProviders: (userToken, searchProviderValue, skip, take) => {
    dispatch(getProviders(userToken, searchProviderValue, skip, take));
  },
  uploadTicketsTable: (userToken, file) =>
    dispatch(uploadTicketsTable(userToken, file)),
  getEvent: (eventId, token, action) => dispatch(getEvent(eventId, token, action)),
  getTicket: (data, token) => dispatch(getTicket(data, token)),
  uploadBarcodes: (data, token, needBookSeats) => dispatch(uploadBarcodes(data, token, needBookSeats)),
  clearCurrentEvent: () => dispatch(clearCurrentEvent()),
  getProviderConfig: (userToken, providerId) =>
    dispatch(getProviderConfig(userToken, providerId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BarCodes)
);
