import React from "react";

import Icon from "../../../../../components/UI/Icon/Icon";
import { IconButton } from "../../../../../components/UI/IconButton/IconButton";
import { useShowActionsMenu } from "../../../../../components/UI/ActionsMenu/hooks/useShowActionsMenu";

export const TransactionActions = ({
  transaction,
  showApproveTransactionModal,
  showEditTransactionModal,
  showRefundTicketsModal,
  resendTicketsHandler,
  resendInvoiceHandler,
  copyOfferLinkHandler,
  copyTicketLinkHandler,
  copyInvoiceLinkHandler,
  cancelOfferHandler,
  allowMaximize = true,
}) => {
  const [showMenuActions] = useShowActionsMenu();

  const handleAccordionClick = (e) => {
    if (allowMaximize) {
      e.target.parentNode.parentNode.parentNode.parentNode.classList.toggle(
        "active"
      );
    }
  };

  const notCanceled = !transaction.isOfferCanceled;

  const isOffer = transaction.isSavedTransaction;
  const isCreatedTransaction = transaction.paymentStatus === 0 && notCanceled;
  const isApprovedTransaction = transaction.paymentStatus === 1 && notCanceled;
  const isOpenOffer = isOffer && isCreatedTransaction;
  const hasInvoice = !!transaction.invoicePDFGuid;

  const canRefund =
    isApprovedTransaction &&
    transaction.paymentType === 1 &&
    !transaction.refunded;

  const accordionClassName =
    "IconButton accordion-btn " +
    (allowMaximize
      ? "btn-primary"
      : "btn-default disable");

  const menuItems = [];

  if (isCreatedTransaction) {
    menuItems.push({
      text: "Approve transaction",
      onClick: showApproveTransactionModal,
      data: transaction,
    });
  } else if (canRefund) {
    menuItems.push({
      text: "Refund transaction",
      onClick: showRefundTicketsModal,
      data: transaction,
    });
  }

  if(hasInvoice)
  {
    menuItems.push({
      text: "Copy invoice link",
      onClick: copyInvoiceLinkHandler,
      data: transaction,
    });
  }

  if (isOpenOffer) {
    menuItems.push({
      text: "Edit",
      onClick: showEditTransactionModal,
      data: transaction,
    });
  }

  // Temporarily disabled, but mb forever
  // menuItems.push({
  //   text: "Delete",
  //   onClick: showDeleteTransactionModal,
  //   data: transaction,
  //   className: "menu-item-red",
  // });

  if (isOpenOffer) {
    menuItems.push({
      text: "Resend offer",
      onClick: resendInvoiceHandler,
      data: transaction,
    });

    menuItems.push({
      text: "Copy offer link",
      onClick: copyOfferLinkHandler,
      data: transaction,
    });
  }

  if (isApprovedTransaction) {
    menuItems.push({
      text: "Resend tickets",
      onClick: resendTicketsHandler,
      data: transaction,
    });

    menuItems.push({
      text: "Copy ticket link",
      onClick: copyTicketLinkHandler,
      data: transaction,
    });
  }

  if (isOpenOffer) {
    menuItems.push({
      text: "Cancel offer",
      onClick: cancelOfferHandler,
      data: transaction,
    });
  }

  if (transaction.paymentStatus === 2) {
    menuItems.push({
      text: "Aprrove transaction",
      onClick: showApproveTransactionModal,
      data: transaction,
    });
  }

  const optionsClassName =
    menuItems.length > 0
      ? "btn-primary IconButton primary trigger-actions-menu"
      : "IconButton gray trigger-actions-menu";

  const optionsIconName = menuItems.length > 0 ? "options" : "options-gray";

  const menuOptions = {
    items: menuItems,
    minMenuHeight: 35 * menuItems.length,
    offsetY: 7,
  };

  return (
    <div className="buttons-block" key={transaction.id}>
      <IconButton
        iconName={optionsIconName}
        className={optionsClassName}
        onClick={showMenuActions(menuOptions)}
      />

      <button
        className={accordionClassName}
        onClick={(e) => {
          handleAccordionClick(e);
        }}
      >
        <Icon name={"chevron"} />
      </button>
    </div>
  );
};
