import { toast } from "react-toastify";

import * as actionTypes from "../actionTypes/transactionsTypes";
import axios from "../../axios-instance";
import { getHeaders } from "../../helpers/getHeaders";

const getTransactionsStart = () => ({
  type: actionTypes.GET_TRANSACTIONS_START,
});

const getTransactionsSuccess = (data) => ({
  type: actionTypes.GET_TRANSACTIONS_SUCCESS,
  payload: data,
});

const getTransactionsFail = (error) => ({
  type: actionTypes.GET_TRANSACTIONS_FAIL,
  error,
});

export const getTransactions =
  (
    userToken,
    searchQuery,
    searchByInputNumber,
    paymentStatuses,
    eventId,
    transactionStatus,
    page,
    pageSize,
    sortingType,
    paymentTypes,
  ) =>
  (dispatch) => {
    dispatch(getTransactionsStart());

    const bodyRequest = {
      searchQuery,
      searchByInputNumber,
      paymentStatuses,
      eventId,
      transactionStatus,
      page,
      pageSize,
      sortingType,
      paymentTypes,
    };

    return axios
      .post(`/ManageTransactions/List`, bodyRequest, {
        headers: getHeaders(userToken),
      })
      .then((response) => {
        if (response.data) {
          return response.data;
        }

        throw new Error(response.data.errorMessage);
      })
      .then((result) => {
        dispatch(getTransactionsSuccess(result));
        return result;
      })
      .catch((err) => {
        dispatch(getTransactionsFail(err));
      });
  };

export const approveTransaction =
  (userToken, id, makeFiscalization, onConfirm) => (dispatch) => {
    return axios
      .post(
        `/ManageTransactions/ApproveTransaction?transactionId=${id}&makeFiscalization=${makeFiscalization}`,
        null,
        {
          headers: getHeaders(userToken),
        }
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: actionTypes.APPROVE_TRANSACTION,
            payload: id,
          })
          ;

          onConfirm();

          toast.success("Transaction successfully approved");
          return;
        }

        throw new Error(response.data.errorMessage);
      })
      .catch((err) => {
        toast.error("Transaction not approved");
      });
  };

export const deleteTransaction = (userToken, id) => (dispatch) => {
  return axios
    .delete(`/ManageTransactions/DeleteTransaction/${id}`, {
      headers: getHeaders(userToken),
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: actionTypes.DELETE_TRANSACTION,
          payload: id,
        });

        toast.success("Transaction successfully deleted");
        return;
      }

      throw new Error(response.data.errorMessage);
    })
    .catch((err) => {
      toast.error("Transaction not deleted");
    });
};

export const editTransaction = (userToken, transaction) => (dispatch) => {
  return axios
    .put(
      `/ManageTransactions/UpdateTransaction/${transaction.id}`,
      transaction,
      {
        headers: getHeaders(userToken),
      }
    )
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: actionTypes.EDIT_TRANSACTION,
          payload: transaction,
        });

        toast.success("Information successfully saved");
        return;
      }

      throw new Error(response.data.errorMessage);
    })
    .catch((err) => {
      toast.error("Information not saved");
    });
};

export const clearTransactions = () => (dispatch) =>
  dispatch({
    type: actionTypes.CLEAR_TRANSACTIONS,
  });

export const deleteSeatsTransaction =
  (userToken, transaction, seatIds) => (dispatch) => {
    return axios
      .delete("/ManageTransactions/DeleteSeats", {
        headers: getHeaders(userToken),
        data: seatIds,
      })
      .then((response) => {
        if (response.status === 200) {
          transaction.seats = transaction.seats.filter(
            (seat) => !seatIds.includes(seat.id)
          );

          dispatch({
            type: actionTypes.DELETE_SEATS_TRANSACTION,
            payload: transaction,
          });
          return;
        }

        throw new Error(response.data.errorMessage);
      })
      .catch((err) => {
        toast.error("Seats not deleted");
      });
  };

export const cancelOfferTransaction =
  (userToken, transaction) => (dispatch) => {
    return axios
      .post(`/Transaction/CancelOfferTransaction/${transaction.id}`, null, {
        headers: getHeaders(userToken),
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Offer successfully canceled");

          transaction.isOfferCanceled = true;

          dispatch({
            type: actionTypes.DELETE_SEATS_TRANSACTION,
            payload: transaction,
          });
          return;
        }

        throw new Error(response.data.errorMessage);
      })
      .catch((err) => {
        toast.error("Offer not canceled");
      });
  };
