export const paymentTypes = {
    undefined: 0,
    online: 1,
    cash: 2,
    pin: 3,
    generated: 4,
    guest: 5,
    delayedPayment: 6,
}

export const paymentOptions = [
  { value: 1, label: "Online" },
  { value: 2, label: "Cash" },
  { value: 3, label: "Pin" },
  { value: 4, label: "Generated" },
  { value: 5, label: "Guest" },
  { value: 6, label: "Delayed Payment" },
  { value: 7, label: "Uploaded" },
  { value: 8, label: "Season Pass" },
  { value: 9, label: "Invoice" },
];

export const getPaymentTypeLabel = (paymentType: number) => {
    return paymentType == 0 && 'Undefined'
        || paymentType == 1 && 'Online'
        || paymentType == 2 && 'Cash'
        || paymentType == 3 && 'Pin'
        || paymentType == 4 && 'Generated'
        || paymentType == 5 && 'Guest'
        || paymentType == 6 && 'Delayed Payment (Invoice)'
        || paymentType == 7 && 'Uploaded'
        || paymentType == 8 && 'Season Pass'
        || '';
}