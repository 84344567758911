import React from "react";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import "./EmailModal.sass";
import "../Emails.sass";

export const EmailModal = ({ email, errorMessage }) => {
  const MySwal = withReactContent(swal);
  if (!email) {
    MySwal.close();
    return;
  };

  const date = new Date(email.modifiedAtUtc);
  const isColoredStatus = email.status === "delivered" || email.status === "open"
    || email.status === "click" || email.status === "bounce" || email.status === "processed";

  return (
    <section className="email-modal">
      <h4 className="email-modal__title">{email.subject}</h4>
      <div className="email-modal__info">
        <div>
          <p><strong>Date: </strong>{date.toLocaleDateString()} {date.toLocaleTimeString()}</p>
          <p><strong>Email: </strong>{email.toAddress}</p>
          {email.status !== "delivered" && email.status !== "open" && email.status !== "click" && (
            <p><strong>Problematic message: </strong>{errorMessage || 'N/A'}</p>
          )}
        </div>
        {isColoredStatus ? (
          <div className={`status-label status-label--width-auto status-label--${email.status}`}>
            {email.status}
          </div>
        ) : (
          <div className={`status-label status-label--width-auto status-label--other`}>
            {`${email.status}`}
          </div>
        )}
      </div>
      <div className="terms" dangerouslySetInnerHTML={{ __html: email.body }}>
        </div>
    </section>
  )
}
