import React, { useEffect, useState } from "react";

import ls from 'localstorage-slim';
import Container from "../../components/UI/Container/Container";
import PageTitle from "../../components/UI/PageTitle/PageTitle";
import NewLoader from "../../components/NewLoader/NewLoader";
import ReactTable from "react-table";
import axios from '../../axios-instance';
import { getHeaders } from "../../helpers/getHeaders";
import { getCurrencieLabel } from "../../constants/currencies";
import ROUTES from "../../constants/routes";

import styles from './ProductDefinitions.module.sass';

export const ProductDefinitions = () => {
    const [state, setStateInternal] = useState({
        loading: true,
        products: []
    });

    const setState = (changes) => setStateInternal({ ...state, ...changes });

    const user = ls.get('user');

    useEffect(() => {
        axios.get('/ProductDefinition', {
            headers: getHeaders(user.token),
        }).then((r) => {
            setState({
                products: r.data,
                loading: false
            });
        })
    }, []);

    const goToProductDefinitionPage = (id) => {
        window.location.pathname = ROUTES.PRODUCT_DEFINITION_DETAIL.replace(":id", id)
    }

    const tableColumns = [
        {
            Header: "Name",
            accessor: "name",
            resizable: false,
            width: 400,
        },
        {
            Header: "Type",
            accessor: "type",
            Cell: ({ row }) => {
                return <p>{row.type === 0 && 'Ticket'
                    || row.type === 1 && 'Membership'
                    || 'Unknown'}</p>
            },
            resizable: false,
            width: 200,
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ row }) => {
                return <p>{row.price} {getCurrencieLabel(row._original.currencyId)}</p>
            },
            resizable: false,
            width: 200,
        }
    ]

    return <Container>
        <PageTitle>Products</PageTitle>

        {state.loading
            ? <NewLoader />
            : (
                <ReactTable
                    data={state.products}
                    columns={tableColumns}
                    showPagination={false}
                    minRows={0}
                    sortable={false}
                    defaultPageSize={state.products.length}
                    getTrProps={(state, rowInfo) => ({
                        onClick: (e) => {
                            goToProductDefinitionPage(rowInfo.original.id, e);
                        },
                    })}
                />
            )
        }
    </Container>
}