import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Select from 'react-select';
import { deleteImage, getWalletInfo, getWalletSamples, updateHeaderImage } from "../../../../../store/actions/eventsActions";
import NewLoader from "../../../../../components/NewLoader/NewLoader";
import Dropzone from "../../../../Upload/Dropzone/Dropzone";

import './EventWallets.sass';
import { GoogleWallet } from "./GoogleWallet/GoogleWallet";
import { AppleWallet } from "./AppleWallet/AppleWallet";
import { IconButton } from "../../../../../components/UI/IconButton/IconButton";

const walletTypes = [
  { value: 1, label: 'Android' },
  { value: 2, label: 'iPhone' },
]

class EventWallets extends Component {
  state = {
    eventId: this.props.match.params.id,
    walletInfo: {
      id: null,
      season: {
        eventId: null,
      }
    },
    loading: true,
    currentWalletType: walletTypes[0],
    googleLogo: '',
    googleFooter: '',
    appleIcon: '',
    appleBackground: '',
    appleThumbnail: '',
  }

  static getDerivedStateFromProps(props, state) {
    return {
      walletInfo: { ...props.walletInfo },
      walletSamples: { ...props.walletSamples }
    };
  };

  handleChangeWallet = (wallet) => {
    this.setState({ currentWalletType: wallet })
  }

  handleUploadFile = (files, imageType) => {
    this.props.updateImage(
      this.props.user.token,
      this.state.eventId,
      files,
      imageType
    ).then(() => {
      this.props.getWalletSamples(this.state.eventId);
      this.setState({ loading: false })
    });
  };

  handleDeleteImage = (imageType) => {
    this.props.deleteImage(this.state.eventId, imageType)
      .then(() => {
        this.props.getWalletSamples(this.state.eventId)
      })
  }

  componentDidMount() {
    const { getWalletInfo, getWalletSamples } = this.props;
    const { eventId } = this.state;

    Promise.all([getWalletInfo(eventId), getWalletSamples(eventId)])
    .then(() => {
      this.setState({ loading: false })
    })
    .catch(() => {
      this.setState({ loading: false })
    });
  }

  render() {
    const {
      walletInfo,
      walletSamples,
      loading,
      currentWalletType,
    } = this.state;

    const { apple, google } = this.props.walletSamples;

    let eventName = walletInfo.name;
    let updatedAt = 'N/A';

    if (walletInfo.season && walletInfo.id === walletInfo.season.seasonBaseEventId) {
      eventName = walletInfo.season.currentEventName;
    }

    if (walletInfo.lastWalletUpdateStartDate) {
      if ((walletInfo.lastWalletUpdateStartDate && !walletInfo.lastWalletUpdateFinishDate) ||
        walletInfo.lastWalletUpdateStartDate > walletInfo.lastWalletUpdateFinishDate
      ) {
        updatedAt = 'In progress'
      } else {
        updatedAt = moment(walletInfo.lastWalletUpdateFinishDate).format('lll')
      }
    }

    const customSelectStyles = {
      control: (base) => ({
        ...base,
        height: '46px',
        'min-height': '46px',
        cursor: 'pointer',
      }),
      placeholder: (base) => ({
        ...base,
        color: '#ABABAB',
      }),
    };

    if (loading) {
      return (
        <div className="wallets">
          <NewLoader />
        </div>
      );
    }

    return (
      <section className="wallets">
        <div className="wallets__editor">
          <div className="wallets__info">
            <div className="add-event__row">
              <div className="add-event__column">
                <div className="add-event__block">
                  <label>QR code visible from event</label>
                  <span className="add-event__block__count">
                    {eventName}
                  </span>
                </div>
              </div>

              <div className="add-event__column">
                <div className="add-event__block">
                  <label>Updated at</label>
                  <span className="add-event__block__count">
                    {updatedAt}
                  </span>
                </div>
              </div>
            </div>

            <div className="add-event__row add-event__row--width-divider">
              <div className="add-event__column">
                <div className="add-event__block">
                  <label>QR code</label>
                  <span className="add-event__block__count">
                    {walletInfo.isQrCodeVisible ? 'Visible' : 'Not visible'}
                  </span>
                </div>
              </div>

              {!walletInfo.isQrCodeVisible && (
                <div className="add-event__column">
                  <div className="add-event__block">
                    <label>QR code will be visible at</label>
                    <span className="add-event__block__count">
                      {moment(walletInfo.visibilityDate).format('lll')}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <Select
            options={walletTypes}
            placeholder='Wallet type'
            name='walletType'
            classNamePrefix='custom-select'
            value={currentWalletType}
            onChange={this.handleChangeWallet}
            className='custom-select wallets__select'
            styles={customSelectStyles}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#6071B5',
                primary25: '#F2F4FE',
                primary50: '#F2F4FE',
                primary75: '#F2F4FE',
              },
            })}
          />

          <div className={`wallets__images-part ${
            currentWalletType.value === 1 ?
            'wallets__images-part--google' :
            'wallets__images-part--apple'
          }`}>
            {currentWalletType.value === 1 ? (
              <>
                <div className="wallets__image-container">
                  <label className="wallets__image-label">Logo</label>
                  <p className="wallets__image-sublabel">(recommended size: 660 x 660 px)</p>
                  <Dropzone
                    imgUrl={google.logoImageUrl}
                    onFilesAdded={(files) => this.handleUploadFile(files, 3)}
                    onButtonEnable={() => { }}
                    unsupportedFileFormatHandle={() => { }}
                    onlyOneFileErrorHandle={() => { }}
                  />
                  {!!google.logoImageUrl && (<IconButton
                    iconName="trash"
                    className="btn-red IconButton"
                    onClick={() => this.handleDeleteImage(3)}
                  />)}
                </div>
                <div className="wallets__image-container">
                  <label className="wallets__image-label">Footer</label>
                  <p className="wallets__image-sublabel">(recommended size: 1600 x 80 px)</p>
                  <Dropzone
                    imgUrl={google.footerImageUrl}
                    onFilesAdded={(files) => this.handleUploadFile(files, 4)}
                    onButtonEnable={() => { }}
                    unsupportedFileFormatHandle={() => { }}
                    onlyOneFileErrorHandle={() => { }}
                  />
                  {!!google.footerImageUrl && (<IconButton
                    iconName="trash"
                    className="btn-red IconButton"
                    onClick={() => this.handleDeleteImage(4)}
                  />)}
                </div>
              </>
            ) : (
              <>
                <div className="wallets__image-container">
                  <label className="wallets__image-label">Logo</label>
                  <p className="wallets__image-sublabel">(recommended size: 320 x 100 px)</p>
                  <Dropzone
                    imgUrl={apple.iconImageUrl}
                    onFilesAdded={(files) => this.handleUploadFile(files, 5)}
                    onButtonEnable={() => { }}
                    unsupportedFileFormatHandle={() => { }}
                    onlyOneFileErrorHandle={() => { }}
                  />
                  {!!apple.iconImageUrl && (<IconButton
                    iconName="trash"
                    className="btn-red IconButton"
                    onClick={() => this.handleDeleteImage(5)}
                  />)}
                </div>
                <div className="wallets__image-container">
                  <label className="wallets__image-label">Background</label>
                  <p className="wallets__image-sublabel">(recommended size: 360 x 440 px)</p>
                  <Dropzone
                    imgUrl={apple.backgroundImageUrl}
                    onFilesAdded={(files) => this.handleUploadFile(files, 6)}
                    onButtonEnable={() => { }}
                    unsupportedFileFormatHandle={() => { }}
                    onlyOneFileErrorHandle={() => { }}
                  />
                  {!!apple.backgroundImageUrl && (<IconButton
                    iconName="trash"
                    className="btn-red IconButton"
                    onClick={() => this.handleDeleteImage(6)}
                  />)}
                </div>
                <div className="wallets__image-container">
                  <label className="wallets__image-label">Thumbnail</label>
                  <p className="wallets__image-sublabel">(recommended size: 120 x 180 px, 180 x 120 px)</p>
                  <Dropzone
                    imgUrl={apple.thumbnailImageUrl}
                    onFilesAdded={(files) => this.handleUploadFile(files, 7)}
                    onButtonEnable={() => { }}
                    unsupportedFileFormatHandle={() => { }}
                    onlyOneFileErrorHandle={() => { }}
                  />
                  {!!apple.thumbnailImageUrl && (<IconButton
                    iconName="trash"
                    className="btn-red IconButton"
                    onClick={() => this.handleDeleteImage(7)}
                  />)}
                </div>
              </>
            )}
          </div>
        </div>

        <div className="wallets__preview-wrapper">
          <div className="wallets__preview">
            {currentWalletType.value === 1 && (
              <>
                <GoogleWallet wallet={google} isQrCodeVisible={walletInfo.isQrCodeVisible} />
                <p className="wallets__info-message">
                  *color of text and background will be adapted to uploaded images
                </p>
              </>
            )}
            {currentWalletType.value === 2 && (
              <AppleWallet wallet={apple} isQrCodeVisible={walletInfo.isQrCodeVisible} />
            )}
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = ({
  auth: { user },
  events: { walletInfo, walletSamples },
}) => ({ walletInfo, user, walletSamples });

const mapDispatchToProps = (dispatch) => ({
  getWalletInfo: (eventId) => dispatch(getWalletInfo(eventId)),
  getWalletSamples: (eventId) => dispatch(getWalletSamples(eventId)),
  updateImage: (token, eventId, files, imageType) =>
    dispatch(updateHeaderImage(token, eventId, files, imageType)),
  deleteImage: (eventId, imageType) => dispatch(deleteImage(eventId, imageType))
});

export default connect(mapStateToProps, mapDispatchToProps)(EventWallets);